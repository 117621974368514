<div class="button-group" role="group">
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'left'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'left' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_left" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'center'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'center' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_center" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'right'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'right' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_right" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'justify'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'justify' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_justify" class="ui-element"></use>
        </svg>
    </button>
</div>

<nocodex-font-family-v2 [formControl]="fontFamilyControl" (fontChanged)="onFontFamilyChanged($event)"></nocodex-font-family-v2>
<div class="small-number-wrapper mb-2">
    <label class="small-input-label w-1/2">Font-Size</label>
    <codex-viewport-selector
        [formGoup]="fontSizeControl"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onFontSizeChanged($event)"></codex-viewport-selector>
</div>
<div class="small-number-wrapper mb-2">
    <label class="small-input-label">Letter spacing</label>
    <codex-viewport-selector
        [formGoup]="letterSpacingControl"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onLetterSpacingChanged($event)"></codex-viewport-selector>
</div>

<div class="small-number-wrapper mb-2">
    <label class="small-input-label">{{ 'v2.part.style.font.lineheight' | translate }}</label>
    <codex-viewport-selector
        [viewPort]="_fontStyle.lineHeightValue"
        [unit]="_fontStyle.lineHeightUnit"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onLineHeightChanged($event)"></codex-viewport-selector>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.style' | translate }}</label>
    <select class="small-input" [formControl]="fontStyleControl" (change)="handleStyleChanged()">
        <option value="normal">Normal</option>
        <option value="italic">Italic</option>
        <option value="oblique">Oblique</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.weight' | translate }}</label>
    <select class="small-input" [formControl]="fontWeightControl" (change)="handleWeightChanged()">
        <option value="100">Thin</option>
        <option value="200">Extra Light</option>
        <option value="300">Light</option>
        <option value="400">Normal</option>
        <option value="500">Medium</option>
        <option value="600">Semi Bold</option>
        <option value="700">Bold</option>
        <option value="800">Extra Bold</option>
        <option value="900">Black</option>
    </select>
</div>

<div class="small-color-picker-wrapper">
    <label class="small-input-label">{{ 'v2.part.style.font.color' | translate }}</label>
    <nocodex-color-picker-v2
        class="small-color-picker"
        [value]="!_fontStyle.colorV2 ? _fontStyle.color : null"
        [colorV2]="!!_fontStyle.colorV2 ? _fontStyle.colorV2 : null"
        (colorChangedV2)="onChangeColor($event)">
    </nocodex-color-picker-v2>
</div>

<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.stretch' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontStretch" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="semi-condensed">Semi condensed</option>
        <option value="condensed">Condensed</option>
        <option value="extra-condensed">Extra condensed</option>
        <option value="ultra-condensed">Ultra condensed</option>
        <option value="semi-expanded">Semi expanded</option>
        <option value="expanded">Expanded</option>
        <option value="extra-expanded">Extra expanded</option>
        <option value="ultra-expanded">Ultra expanded</option>
    </select>
</div>

<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.variant' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontVariant" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="small-caps">Small caps</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.variantcaps' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontVariantCaps" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="small-caps">Small caps</option>
        <option value="all-small-caps">All small caps</option>
        <option value="petite-caps">Mini caps</option>
        <option value="all-petite-caps">All mini caps</option>
        <option value="unicase">Unicase</option>
        <option value="titling-caps">Titling caps</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>

<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.textdecoration.line' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.textDecorationLine" (change)="onChangeFont()">
        <option value="none">None</option>
        <option value="underline">Underline</option>
        <option value="overline">Overline</option>
        <option value="line-through">Line through</option>
        <option value="blink">Blink</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.textdecoration.style' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.textDecorationStyle" (change)="onChangeFont()">
        <option value="solid">Solid</option>
        <option value="double">Double</option>
        <option value="dotted">Dotted</option>
        <option value="dashed">Dashed</option>
        <option value="wavy">Wavy</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
<div class="small-number-wrapper mb-2">
    <label class="small-input-label w-1/2">{{ 'v2.part.style.font.textdecoration.thickness' | translate }}</label>
    <codex-viewport-selector
        [formGoup]="textDecorationThicknessControl"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onTextDecorationThicknessChanged($event)"></codex-viewport-selector>
</div>
<div class="small-color-picker-wrapper">
    <label class="small-input-label">{{ 'v2.part.style.font.textdecoration.color' | translate }}</label>
    <nocodex-color-picker-v2
        class="small-color-picker"
        [colorV2]="_fontStyle.textDecorationColor"
        (colorChangedV2)="onChangeTextDecorationColor($event)">
    </nocodex-color-picker-v2>
</div>
