import { Type } from 'class-transformer';
import { PartStyle } from './part-style.model';
import { backofficeEnvironment } from '@shared/environment';
import { TextPartStyle } from './parts/layout/text-part/text-part.style';
import { CheckboxFieldPartStyle } from './parts/form/checkbox-field-part/checkbox-field-part.style';
import { TextFieldPartStyle } from './parts/form/text-field-part/text-field-part.style';
import { DateFieldPartStyle } from './parts/form/date-field-part/date-field-part.style';
import { RadiobuttonFieldPartStyle } from './parts/form/radiobutton-field-part/radiobutton-field-part.style';
import { SliderFieldPartStyle } from './parts/form/slider-field-part/slider-field-part.style';
import { InputFieldPartStyle } from './parts/form/input-field-part/input-field-part.style';
import { DropdownFieldPartStyle } from './parts/form/dropdown-field-part/dropdown-field-part.style';
import { NumberInputFieldPartStyle } from './parts/form/number-input-field-part/number-input-field-part.style';
import { PasswordInputFieldPartStyle } from './parts/form/password-input-field-part/password-input-field-part.style';
import { UrlInputFieldPartStyle } from './parts/form/url-input-field-part/url-input-field-part.style';
import { EmailInputFieldPartStyle } from './parts/form/email-input-field-part/email-input-field-part.style';
import { ColorInputFieldPartStyle } from './parts/form/color-input-field-part/color-input-field-part.style';
import { ChipsInputFieldPartStyle } from './parts/form/chips-input-field-part/chips-input-field-part.style';
import { TabsPartStyle } from './parts/containers/tabs-part/tabs-part.style';
import { PlanePartStyle } from './parts/containers/plane-part/plane-part.style';
import { StepperPartStyle } from './parts/containers/stepper-part/stepper-part.style';
import { ButtonPartStyle } from './parts/form/button-part/button-part.style';
import { ButtonGroupPartStyle } from './parts/form/button-group-part/button-group-part.style';
import { LineChartPartStyle } from './parts/charts/line-graph-part/line-chart-part.style';
import { BarChartPartStyle } from './parts/charts/bar-chart-part/bar-chart-part.style';
import { RadarChartPartStyle } from './parts/charts/radar-chart-part/radar-chart-part.style';
import { PieChartPartStyle } from './parts/charts/pie-chart-part/pie-chart-part.style';
import { TreeMapPartStyle } from './parts/charts/tree-map-part/tree-map-part.style';
import { IconButtonPartStyle } from './parts/form/icon-button-part/icon-button-part.style';
import { ImagePartStyle } from './parts/layout/image-part/image-part.style';
import { TemplatePartStyle } from './parts/layout/template-part/template-part.style';
import { LinkPartStyle } from './parts/layout/link-part/link-part.style';
import { HorizontalListPartStyle } from './parts/containers/horizontal-list-part/horizontal-list-part.style';
import { VerticalListPartStyle } from './parts/containers/vertical-list-part/vertical-list-part.style';
import { VerticalDividerPartStyle } from './parts/layout/vertical-divider-part/vertical-divider-part.style';
import { HorizontalDividerPartStyle } from './parts/layout/horizontal-divider-part/horizontal-divider-part.style';
import { IconPartStyle } from './parts/layout/icon-part/icon-part.style';
import { TitlePartStyle } from './parts/layout/title-part/title-part.style';
import { UploaderPartStyle } from './parts/form/uploader-part/uploader-part.style';
import { PagingPartStyle } from './parts/layout/paging-part/paging-part.style';
import { SlideToggleFieldPartStyle } from './parts/form/slide-toggle-field-part/slide-toggle-field-part.style';
import { DataTablePartStyle } from './parts/containers/datatable-part/datatable-part.style';
import { ChipsPartStyle } from './parts/layout/chips-part/chips-part.style';
import { TextPartDetail } from './parts/layout/text-part/text-part.detail';
import { CheckboxFieldPartDetail } from './parts/form/checkbox-field-part/checkbox-field-part.detail';
import { TextFieldPartDetail } from './parts/form/text-field-part/text-field-part.detail';
import { DateFieldPartDetail } from './parts/form/date-field-part/date-field-part.detail';
import { RadiobuttonFieldPartDetail } from './parts/form/radiobutton-field-part/radiobutton-field-part.detail';
import { SliderFieldPartDetail } from './parts/form/slider-field-part/slider-field-part.detail';
import { InputFieldPartDetail } from './parts/form/input-field-part/input-field-part.detail';
import { DropdownFieldPartDetail } from './parts/form/dropdown-field-part/dropdown-field-part.detail';
import { NumberInputFieldPartDetail } from './parts/form/number-input-field-part/number-input-field-part.detail';
import { PasswordInputFieldPartDetail } from './parts/form/password-input-field-part/password-input-field-part.detail';
import { UrlInputFieldPartDetail } from './parts/form/url-input-field-part/url-input-field-part.detail';
import { EmailInputFieldPartDetail } from './parts/form/email-input-field-part/email-input-field-part.detail';
import { ColorInputFieldPartDetail } from './parts/form/color-input-field-part/color-input-field-part.detail';
import { ChipsInputFieldPartDetail } from './parts/form/chips-input-field-part/chips-input-field-part.detail';
import { TabsPartDetail } from './parts/containers/tabs-part/tabs-part.detail';
import { PlanePartDetail } from './parts/containers/plane-part/plane-part.detail';
import { StepperPartDetail } from './parts/containers/stepper-part/stepper-part.detail';
import { ButtonPartDetail } from './parts/form/button-part/button-part.detail';
import { ButtonGroupPartDetail } from './parts/form/button-group-part/button-group-part.detail';
import { LineChartPartDetail } from './parts/charts/line-graph-part/line-chart-part.detail';
import { BarChartPartDetail } from './parts/charts/bar-chart-part/bar-chart-part.detail';
import { RadarChartPartDetail } from './parts/charts/radar-chart-part/radar-chart-part.detail';
import { PieChartPartDetail } from './parts/charts/pie-chart-part/pie-chart-part.detail';
import { TreeMapPartDetail } from './parts/charts/tree-map-part/tree-map-part.detail';
import { IconButtonPartDetail } from './parts/form/icon-button-part/icon-button-part.detail';
import { ImagePartDetail } from './parts/layout/image-part/image-part.detail';
import { TemplatePartDetail } from './parts/layout/template-part/template-part.detail';
import { LinkPartDetail } from './parts/layout/link-part/link-part.detail';
import { HorizontalListPartDetail } from './parts/containers/horizontal-list-part/horizontal-list-part.detail';
import { VerticalListPartDetail } from './parts/containers/vertical-list-part/vertical-list-part.detail';
import { VerticalDividerPartDetail } from './parts/layout/vertical-divider-part/vertical-divider-part.detail';
import { HorizontalDividerPartDetail } from './parts/layout/horizontal-divider-part/horizontal-divider-part.detail';
import { IconPartDetail } from './parts/layout/icon-part/icon-part.detail';
import { TitlePartDetail } from './parts/layout/title-part/title-part.detail';
import { UploaderPartDetail } from './parts/form/uploader-part/uploader-part.detail';
import { PagingPartDetail } from './parts/layout/paging-part/paging-part.detail';
import { SlideToggleFieldPartDetail } from './parts/form/slide-toggle-field-part/slide-toggle-field-part.detail';
import { DataTablePartDetail } from './parts/containers/datatable-part/datatable-part.detail';
import { ChipsPartDetail } from './parts/layout/chips-part/chips-part.detail';
import { PartDetail } from './part-detail.model';
import { PartSubContainer } from './part-sub-container.model';
import { getClosestPartPositioning, PartPositioning, selectCurrentPartPositioningCreateNewIfNotExisting } from './part-positioning.dto';
import { OtpQrPartDetail } from './parts/user/otp-qr-part/otp-qr-part.detail';
import { OtpQrPartStyle } from './parts/user/otp-qr-part/otp-qr-part.style';
import { PositionChanged } from '../../interfaces/events/position-changed.event';
import { SizeChanged } from '../../interfaces/events/size-changed.event';
import { ScreenType, ScreenTypeEditorHeight, ScreenTypeEditorWidth, ScreenTypeMaxWidth, ScreenTypeMinWidth } from './screentype.enum';
import { getSizeXForGridAmount, getSizeYForGridAmount, GridStyleDto } from '../../interfaces/grid/grid.style.dto';
import { HtmlPartStyle } from './parts/custom/html-part/html-part.style';
import { HtmlPartDetail } from './parts/custom/html-part/html-part.detail';
import { AccordionPartDetail } from './parts/containers/accordion-part/accordion-part.detail';
import { AccordionPartStyle } from './parts/containers/accordion-part/accordion-part.style';

export class Part {
    public readonly id: string;
    public readonly selectorId: string;
    public readonly inheritFromPartId: string;
    public positions: PartPositioning[];
    public x: number;
    public positionXUnit: string;
    public y: number;
    public positionYUnit: string;
    public languageCode: string;
    public sizeX: number;
    public sizeXUnit: string;
    public sizeY: number;
    public sizeYUnit: string;
    public sizeXPx: number;
    public sizeYPx: number;
    public verticalAlignment: string;
    public horizontalAlignment: string;
    public inheritContentSize: boolean;
    public fixedPosition: boolean;
    public zIndex: number;
    public hidden: boolean;
    public partTypeId: string;

    //transient
    public selectedPart: boolean;
    //transient
    public draggingPart: boolean;

    @Type(() => PartDetail, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'partType',
            subTypes: [
                { value: TextPartDetail, name: 'text' },
                { value: CheckboxFieldPartDetail, name: 'checkbox-field' },
                { value: TextFieldPartDetail, name: 'text-field' },
                { value: DateFieldPartDetail, name: 'date-field' },
                { value: RadiobuttonFieldPartDetail, name: 'radiobutton-field' },
                { value: SliderFieldPartDetail, name: 'slider-field' },
                { value: InputFieldPartDetail, name: 'input-field' },
                { value: DropdownFieldPartDetail, name: 'dropdown-field' },
                { value: NumberInputFieldPartDetail, name: 'number-input-field' },
                { value: PasswordInputFieldPartDetail, name: 'password-input-field' },
                { value: UrlInputFieldPartDetail, name: 'url-input-field' },
                { value: EmailInputFieldPartDetail, name: 'email-input-field' },
                { value: ColorInputFieldPartDetail, name: 'color-input-field' },
                { value: ChipsInputFieldPartDetail, name: 'chips-input-field' },
                { value: TabsPartDetail, name: 'tabs' },
                { value: PlanePartDetail, name: 'plane' },
                { value: StepperPartDetail, name: 'stepper' },
                { value: ButtonPartDetail, name: 'button' },
                { value: ButtonGroupPartDetail, name: 'button-group' },
                { value: LineChartPartDetail, name: 'line-chart' },
                { value: BarChartPartDetail, name: 'bar-chart' },
                { value: RadarChartPartDetail, name: 'radar-chart' },
                { value: PieChartPartDetail, name: 'pie-chart' },
                { value: TreeMapPartDetail, name: 'tree-map' },
                { value: IconButtonPartDetail, name: 'icon-button' },
                { value: ImagePartDetail, name: 'image' },
                { value: TemplatePartDetail, name: 'template' },
                { value: LinkPartDetail, name: 'link' },
                { value: HorizontalListPartDetail, name: 'horizontal-list' },
                { value: VerticalListPartDetail, name: 'vertical-list' },
                { value: VerticalDividerPartDetail, name: 'vertical-divider' },
                { value: HorizontalDividerPartDetail, name: 'horizontal-divider' },
                { value: IconPartDetail, name: 'icon' },
                { value: TitlePartDetail, name: 'title' },
                { value: UploaderPartDetail, name: 'uploader' },
                { value: PagingPartDetail, name: 'paging' },
                { value: SlideToggleFieldPartDetail, name: 'slide-toggle' },
                { value: DataTablePartDetail, name: 'datatable' },
                { value: ChipsPartDetail, name: 'chips' },
                { value: OtpQrPartDetail, name: 'otp-qr' },
                { value: HtmlPartDetail, name: 'html' },
                { value: AccordionPartDetail, name: 'accordion' },
            ],
        },
    })
    public detail: PartDetail;

    @Type(() => PartStyle, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'partType',
            subTypes: [
                { value: TextPartStyle, name: 'text' },
                { value: CheckboxFieldPartStyle, name: 'checkbox-field' },
                { value: TextFieldPartStyle, name: 'text-field' },
                { value: DateFieldPartStyle, name: 'date-field' },
                { value: RadiobuttonFieldPartStyle, name: 'radiobutton-field' },
                { value: SliderFieldPartStyle, name: 'slider-field' },
                { value: InputFieldPartStyle, name: 'input-field' },
                { value: DropdownFieldPartStyle, name: 'dropdown-field' },
                { value: NumberInputFieldPartStyle, name: 'number-input-field' },
                { value: PasswordInputFieldPartStyle, name: 'password-input-field' },
                { value: UrlInputFieldPartStyle, name: 'url-input-field' },
                { value: EmailInputFieldPartStyle, name: 'email-input-field' },
                { value: ColorInputFieldPartStyle, name: 'color-input-field' },
                { value: ChipsInputFieldPartStyle, name: 'chips-input-field' },
                { value: TabsPartStyle, name: 'tabs' },
                { value: PlanePartStyle, name: 'plane' },
                { value: StepperPartStyle, name: 'stepper' },
                { value: ButtonPartStyle, name: 'button' },
                { value: ButtonGroupPartStyle, name: 'button-group' },
                { value: LineChartPartStyle, name: 'line-chart' },
                { value: BarChartPartStyle, name: 'bar-chart' },
                { value: RadarChartPartStyle, name: 'radar-chart' },
                { value: PieChartPartStyle, name: 'pie-chart' },
                { value: TreeMapPartStyle, name: 'tree-map' },
                { value: IconButtonPartStyle, name: 'icon-button' },
                { value: ImagePartStyle, name: 'image' },
                { value: TemplatePartStyle, name: 'template' },
                { value: LinkPartStyle, name: 'link' },
                { value: HorizontalListPartStyle, name: 'horizontal-list' },
                { value: VerticalListPartStyle, name: 'vertical-list' },
                { value: VerticalDividerPartStyle, name: 'vertical-divider' },
                { value: HorizontalDividerPartStyle, name: 'horizontal-divider' },
                { value: IconPartStyle, name: 'icon' },
                { value: TitlePartStyle, name: 'title' },
                { value: UploaderPartStyle, name: 'uploader' },
                { value: PagingPartStyle, name: 'paging' },
                { value: SlideToggleFieldPartStyle, name: 'slide-toggle' },
                { value: DataTablePartStyle, name: 'datatable' },
                { value: ChipsPartStyle, name: 'chips' },
                { value: OtpQrPartStyle, name: 'otp-qr' },
                { value: HtmlPartStyle, name: 'html' },
                { value: AccordionPartStyle, name: 'accordion' },
            ],
        },
    })
    public style: PartStyle;

    private maxSizeX: number;
    private maxSizeY: number;
    private minSizeX: number;
    private minSizeY: number;

    public containerId: string;
    public secondaryContainerId: string | undefined;

    // transient
    public containerParts: Part[];
    // transient
    public openedContainerId: string;
    // transient
    public valid = true;
    // transient
    public dragOffsetLeft: number;
    // transient
    public dragOffsetTop: number;
    // transient
    public containerOffsetLeft: number;
    // transient
    public containerOffsetTop: number;
    // transient
    public resizeContext: {
        originalPartElementSizeX?: number | undefined;
        originalPartElementSizeY?: number | undefined;
        originalPartElementTop?: number | undefined;
        originalPartElementLeft?: number | undefined;
        parentResizeContext: {
            parentSizeXUnit: string;
            parentSizeYUnit: string;
            parentSizeX: number;
            parentSizeY: number;
            parentGrid: GridStyleDto;
            parentElementHeight: number;
            parentElementWidth: number;
            parentElementTop: number;
            parentElementScrollTop: number;
            parentElementLeft: number;
            parentElementScrollLeft: number;
        };
    };
    // transient
    public grid: GridStyleDto;

    public resizeAllUnits(newSizeX: number, newSizeY: number, top: number, left: number, positioning: PartPositioning): void {
        let changedSize = false;
        if (positioning) {
            if (!!newSizeX) {
                if (positioning.sizeXUnit === 'pixels' && newSizeX > this.maxSizeX) {
                    newSizeX = this.maxSizeX;
                }
                if (positioning.sizeXUnit === 'pixels' && newSizeX < this.minSizeX) {
                    newSizeX = this.minSizeX;
                }
                if (positioning.sizeX !== newSizeX) {
                    positioning.sizeX = newSizeX;
                    changedSize = true;
                }
            }

            if (!!newSizeY) {
                if (positioning.sizeYUnit === 'pixels' && newSizeY > this.maxSizeY) {
                    newSizeY = this.maxSizeY;
                }
                if (positioning.sizeYUnit === 'pixels' && newSizeY < this.minSizeY) {
                    newSizeY = this.minSizeY;
                }
                if (positioning.sizeY !== newSizeY) {
                    positioning.sizeY = newSizeY;
                    changedSize = true;
                }
            }

            if ((!!top || top === 0) && positioning.y !== top) {
                if (top < 0) {
                    top = 0;
                }
                positioning.y = top;
            }

            if ((!!left || left === 0) && positioning.x !== left) {
                if (left < 0) {
                    left = 0;
                }
                positioning.x = left;
            }
        }
    }

    public moveLeft(multiplier: number) {
        this.x = this.x - 1 * multiplier;
    }

    public moveRight(multiplier: number) {
        this.x = this.x + 1 * multiplier;
    }

    public moveUp(multiplier: number) {
        this.y = this.y - 1 * multiplier;
    }

    public moveDown(multiplier: number) {
        this.y = this.y + 1 * multiplier;
    }

    public moveY(newY: number) {
        this.y = newY;
    }

    public move(newX: number, newY: number, currentScreenType: number): boolean {
        const partPositioning: PartPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, this.positions);
        if ((partPositioning && newX !== this.x) || newY !== this.y) {
            partPositioning.x = newX;
            partPositioning.y = newY;
            return true;
        }
        return false;
    }

    public isValidSizeX(sizeX: number) {
        if (this.sizeXUnit === 'pixels') {
            return (!this.maxSizeX || sizeX <= this.maxSizeX) && sizeX >= this.minSizeX;
        } else {
            return true;
        }
    }

    public isValidSizeY(sizeY: number): boolean {
        if (this.sizeYUnit === 'pixels') {
            return (!this.maxSizeY || sizeY <= this.maxSizeY) && sizeY >= this.minSizeY;
        } else {
            return true;
        }
    }

    public getValidationErrors(): string[] {
        let errors = [];
        if (!this.isValid()) {
            errors = errors.concat(this.detail.getValidationErrors());
        }
        return errors;
    }

    public toggleHidden(): void {
        this.hidden = !this.hidden;
    }

    public isValid(): boolean {
        let valid = true;
        if (!!this.detail) {
            valid = valid && this.detail.isValid();
        }
        this.valid = valid;
        return this.valid;
    }

    public getSubContainers(): PartSubContainer[] {
        return this.detail.getSubContainers();
    }

    public hasSubContainers(): boolean {
        return this.detail.partType === 'tabs' || this.detail.partType === 'stepper';
    }

    public isContainerPart(): boolean {
        return (
            this.detail?.partType === 'tabs' ||
            this.detail?.partType === 'plane' ||
            this.detail?.partType === 'stepper' ||
            this.detail?.partType === 'horizontal-list' ||
            this.detail?.partType === 'vertical-list' ||
            this.detail?.partType === 'accordion'
        );
    }

    public removeContainerPart(partSelectorId: string) {
        if (this.isContainerPart()) {
            this.detail.removeContainerPart(partSelectorId);
        }
    }

    public updateContainerPart(part: Part) {
        if (this.isContainerPart()) {
            return this.detail.updateContainerPart(part);
        }
    }

    public addContainerPart(part: Part, x: number = 0, y: number = 0, index: number, currentScreenType: number) {
        if (this.isContainerPart()) {
            part.move(x, y, currentScreenType);
            this.detail.addContainerPart(part, index);
        }
    }

    public getSelector(templateScopeName) {
        return `{${templateScopeName}.parts[{selectorId}=='${this.selectorId}']}`;
    }

    public getCertainWidthFromPositioning(
        sizeX: number,
        sizeXUnit: string,
        grid?: GridStyleDto,
        containerWidth: string = '100%',
        x: string = '300px'
    ) {
        if (sizeXUnit === 'pixels') {
            return sizeX + 'px';
        } else if (sizeXUnit === 'percentage') {
            return sizeX + '%';
        } else if (sizeXUnit === 'viewport') {
            return sizeX + 'vw';
        } else if (sizeXUnit === 'grid' && !!grid) {
            let amountOfGaps = grid.gridColumns + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridColumns - 1;
            }
            const gapSize = grid.gridColumnGapSize;
            const amountOfColumns = grid.gridColumns;
            const amountOfGapsCovered = sizeX - 1;
            return (
                'calc(((' +
                containerWidth +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfColumns +
                ') * ' +
                sizeX +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)'
            );
        } else if (sizeXUnit === 'grid-end' && !!grid) {
            let amountOfGaps = grid.gridColumns + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridColumns - 1;
            }
            const gapSize = grid.gridColumnGapSize;
            const amountOfColumns = grid.gridColumns;
            const amountOfGapsCovered = sizeX - 1;
            return (
                'calc((((' +
                containerWidth +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfColumns +
                ') * ' +
                sizeX +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px) ' +
                ' - ' +
                x +
                ' + ' +
                gapSize +
                'px)'
            );
        } else if (sizeXUnit === 'grid-start' && !!grid) {
            let amountOfGaps = grid.gridColumns + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridColumns - 1;
            }
            const gapSize = grid.gridColumnGapSize;
            const amountOfColumns = grid.gridColumns;
            const amountOfGapsCovered = sizeX - 1;
            return (
                'calc((((' +
                containerWidth +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfColumns +
                ') * ' +
                (sizeX - 1) +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px) ' +
                ' - ' +
                x +
                ' + ' +
                gapSize +
                'px)'
            );
        } else if (sizeXUnit === 'unset') {
            return 'unset';
        } else if (sizeXUnit === 'fit-content') {
            return 'fit-content';
        } else {
            return 'initial';
        }
    }

    public getMaxWidthFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerWidth: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainWidthFromPositioning(
                positioning.maxSizeX,
                positioning.maxSizeXUnit,
                grid,
                containerWidth,
                this.getHorizontalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getMinWidthFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerWidth: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainWidthFromPositioning(
                positioning.minSizeX,
                positioning.minSizeXUnit,
                grid,
                containerWidth,
                this.getHorizontalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getWidthFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerWidth: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainWidthFromPositioning(
                positioning.sizeX,
                positioning.sizeXUnit,
                grid,
                containerWidth,
                this.getHorizontalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getWidth(currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto, containerWidth: string = '100%') {
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(currentScreenType),
            ScreenTypeMaxWidth.get(currentScreenType),
            this.positions
        );
        return this.getWidthFromPositioning(positioning, ignoreInherit, grid, containerWidth);
    }

    public getVerticalPositioning(positioning: PartPositioning, grid: GridStyleDto) {
        if (positioning) {
            let yPosition: string | undefined = undefined;
            if (positioning.positionYUnit === 'pixels') {
                yPosition = positioning.y + 'px';
            } else if (positioning.positionYUnit === 'percentage') {
                yPosition = positioning.y + '%';
            } else if (positioning.positionYUnit === 'viewport') {
                yPosition = positioning.y + 'vh';
            } else if (positioning.positionYUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridRows + 1;
                const gapSize = grid.gridRowGapSize;
                const amountOfRows = grid.gridRows;
                const amountOfGapsCovered = positioning.y + 1;
                yPosition =
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfRows +
                    ') * ' +
                    positioning.y +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)';
            }
            return yPosition;
        } else {
            return undefined;
        }
    }

    public getZIndex(positioning: PartPositioning) {
        if (positioning) {
            return positioning.zIndex + '';
        }
    }

    public getHorizontalPositioning(positioning: PartPositioning, grid?: GridStyleDto) {
        if (positioning) {
            let xPosition: string | undefined = undefined;
            if (positioning.positionXUnit === 'pixels') {
                xPosition = positioning.x + 'px';
            } else if (positioning.positionXUnit === 'percentage') {
                xPosition = positioning.x + '%';
            } else if (positioning.positionXUnit === 'viewport') {
                xPosition = positioning.x + 'vh';
            } else if (positioning.positionXUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridColumns + 1;
                const gapSize = grid.gridColumnGapSize;
                const amountOfColumns = grid.gridColumns;
                const amountOfGapsCovered = positioning.x + 1;
                xPosition =
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfColumns +
                    ') * ' +
                    positioning.x +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)';
            }
            return xPosition;
        }
    }

    public getCertainHeightFromPositioning(
        sizeY: number,
        sizeYUnit: string,
        grid?: GridStyleDto,
        containerHeight: string = '100%',
        y: string = '200px'
    ) {
        if (sizeYUnit === 'pixels') {
            return sizeY + 'px';
        } else if (sizeYUnit === 'percentage') {
            return sizeY + '%';
        } else if (sizeYUnit === 'viewport') {
            return sizeY + 'vh';
        } else if (sizeYUnit === 'grid' && !!grid) {
            let amountOfGaps = grid.gridRows + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridRows - 1;
            }
            const gapSize = grid.gridRowGapSize;
            const amountOfRows = grid.gridRows;
            const amountOfGapsCovered = sizeY - 1;
            return (
                'calc(((' +
                containerHeight +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfRows +
                ') * ' +
                sizeY +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)'
            );
        } else if (sizeYUnit === 'grid-end' && !!grid) {
            let amountOfGaps = grid.gridRows + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridRows - 1;
            }
            const gapSize = grid.gridRowGapSize;
            const amountOfRows = grid.gridRows;
            const amountOfGapsCovered = sizeY - 1;
            return (
                'calc((((' +
                containerHeight +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfRows +
                ') * ' +
                sizeY +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)' +
                ' - ' +
                y +
                ' + ' +
                gapSize +
                'px)'
            );
        } else if (sizeYUnit === 'grid-start' && !!grid) {
            let amountOfGaps = grid.gridRows + 1;
            if (backofficeEnvironment.addPaddingToGrid) {
                amountOfGaps = grid.gridRows - 1;
            }
            const gapSize = grid.gridRowGapSize;
            const amountOfRows = grid.gridRows;
            const amountOfGapsCovered = sizeY - 1;
            return (
                'calc((((' +
                containerHeight +
                ' - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfRows +
                ') * ' +
                (sizeY - 1) +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)' +
                ' - ' +
                y +
                ' + ' +
                gapSize +
                'px)'
            );
        } else if (sizeYUnit === 'unset') {
            return 'unset';
        } else if (sizeYUnit === 'fit-content') {
            return 'fit-content';
        } else {
            return 'initial';
        }
    }

    public getMinHeightFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerHeight: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainHeightFromPositioning(
                positioning.minSizeY,
                positioning.minSizeYUnit,
                grid,
                containerHeight,
                this.getVerticalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getMaxHeightFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerHeight: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainHeightFromPositioning(
                positioning.maxSizeY,
                positioning.maxSizeYUnit,
                grid,
                containerHeight,
                this.getVerticalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getFixedFromPositioning(positioning: PartPositioning) {
        if (positioning) {
            return positioning.fixedPosition ? 'fixed' : undefined;
        } else {
            return undefined;
        }
    }
    public getHeightFromPositioning(
        positioning: PartPositioning,
        ignoreInherit?: boolean,
        grid?: GridStyleDto,
        containerHeight: string = '100%'
    ) {
        if (positioning) {
            return this.getCertainHeightFromPositioning(
                positioning.sizeY,
                positioning.sizeYUnit,
                grid,
                containerHeight,
                this.getVerticalPositioning(positioning, grid)
            );
        } else {
            return 'initial';
        }
    }

    public getHeight(currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto, containerHeight: string = '100%') {
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(currentScreenType),
            ScreenTypeMaxWidth.get(currentScreenType),
            this.positions
        );
        return this.getHeightFromPositioning(positioning, ignoreInherit, grid, containerHeight);
    }

    public getMaxHeight(currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto, containerHeight: string = '100%') {
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(currentScreenType),
            ScreenTypeMaxWidth.get(currentScreenType),
            this.positions
        );
        return this.getMaxHeightFromPositioning(positioning, ignoreInherit, grid, containerHeight);
    }

    public getMinHeight(currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto, containerHeight: string = '100%') {
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(currentScreenType),
            ScreenTypeMaxWidth.get(currentScreenType),
            this.positions
        );
        return this.getMinHeightFromPositioning(positioning, ignoreInherit, grid, containerHeight);
    }
}

export function resizePartRight(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const width = Math.round((pointerPositionX - part.resizeContext.originalPartElementLeft) / scale);
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let left = null;
    if (partPositioning) {
        if (partPositioning.horizontalAlignment === 'right') {
            left =
                (parentResizeContext.parentElementWidth -
                    (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft))) /
                scale;
        }
    }
    return normalizeResizeResult(
        { width, height: null, top: null, left },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartLeft(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const width = (part.resizeContext.originalPartElementLeft + part.resizeContext.originalPartElementSizeX - pointerPositionX) / scale;
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let left = null;
    if (partPositioning) {
        if (partPositioning.horizontalAlignment === 'left') {
            left = (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft)) / scale;
        }
    }
    return normalizeResizeResult(
        { width, height: null, top: null, left },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartBottom(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const height = Math.round((pointerPositionY - part.resizeContext.originalPartElementTop) / scale);
    let top = null;
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'bottom') {
            const parentResizeContext = part.resizeContext.parentResizeContext;
            top =
                (parentResizeContext.parentElementHeight -
                    (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop))) /
                scale;
        }
    }
    return normalizeResizeResult(
        { width: null, height, top, left: null },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartTop(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const height = (part.resizeContext.originalPartElementTop + part.resizeContext.originalPartElementSizeY - pointerPositionY) / scale;
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let top = null;
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'top') {
            top = (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop)) / scale;
        }
    }
    return normalizeResizeResult(
        { width: null, height, top, left: null },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartRightBottom(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const width = Math.round((pointerPositionX - part.resizeContext.originalPartElementLeft) / scale);
    const height = Math.round((pointerPositionY - part.resizeContext.originalPartElementTop) / scale);
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let top = null;
    let left = null;
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'bottom') {
            top =
                (parentResizeContext.parentElementHeight -
                    (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop))) /
                scale;
        }
        if (partPositioning.horizontalAlignment === 'right') {
            left =
                (parentResizeContext.parentElementWidth -
                    (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft))) /
                scale;
        }
    }
    return normalizeResizeResult(
        {
            width,
            height,
            top,
            left,
        },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartLeftBottom(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const width = (part.resizeContext.originalPartElementLeft + part.resizeContext.originalPartElementSizeX - pointerPositionX) / scale;
    const height = Math.round((pointerPositionY - part.resizeContext.originalPartElementTop) / scale);
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let top = null;
    let left = null;
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'bottom') {
            top =
                (parentResizeContext.parentElementHeight -
                    (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop))) /
                scale;
        }

        if (partPositioning.horizontalAlignment === 'left') {
            left = (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft)) / scale;
        }
    }

    return normalizeResizeResult(
        {
            width,
            height,
            top,
            left,
        },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartRightTop(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const width = Math.round((pointerPositionX - part.resizeContext.originalPartElementLeft) / scale);
    const height = (part.resizeContext.originalPartElementTop + part.resizeContext.originalPartElementSizeY - pointerPositionY) / scale;
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let top = null;
    let left = null;
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'top') {
            top = (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop)) / scale;
        }

        if (partPositioning.horizontalAlignment === 'right') {
            left =
                (parentResizeContext.parentElementWidth -
                    (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft))) /
                scale;
        }
    }
    return normalizeResizeResult(
        {
            width,
            height,
            top,
            left,
        },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function resizePartLeftTop(
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number,
    currentViewScreenType: number
): { width: number; height: number; top: number; left: number } {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    const height = (part.resizeContext.originalPartElementTop + part.resizeContext.originalPartElementSizeY - pointerPositionY) / scale;
    const width = (part.resizeContext.originalPartElementLeft + part.resizeContext.originalPartElementSizeX - pointerPositionX) / scale;
    const partPositioning = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    let top = null;
    let left = null;
    if (partPositioning) {
        if (partPositioning.verticalAlignment === 'top') {
            top = (pointerPositionY - (parentResizeContext.parentElementTop + parentResizeContext.parentElementScrollTop)) / scale;
        }

        if (partPositioning.horizontalAlignment === 'left') {
            left = (pointerPositionX - (parentResizeContext.parentElementLeft + parentResizeContext.parentElementScrollLeft)) / scale;
        }
    }

    return normalizeResizeResult(
        {
            width,
            height,
            top,
            left,
        },
        part,
        partPositioning,
        currentScreenType,
        currentViewScreenType,
        scale
    );
}

export function normalizeMoveResult(
    moveResult: {
        x: number;
        y: number;
    },
    part: Part | null,
    partPosition: PartPositioning | null,
    currentScreenType: number
) {
    let x = moveResult.x;
    let y = moveResult.y;
    if (!!part && !!partPosition && !!part.resizeContext) {
        const parentResizeContext = part.resizeContext.parentResizeContext;
        // X
        if (!!x) {
            if (partPosition.positionXUnit === 'grid') {
                const gridSizeX =
                    getSizeXForGridAmount(
                        1,
                        parentResizeContext.parentGrid,
                        parentResizeContext.parentElementWidth,
                        'pixels',
                        currentScreenType
                    ) + parentResizeContext.parentGrid.gridColumnGapSize;
                x = Math.round(x / gridSizeX);
            } else if (partPosition.positionXUnit === 'percentage' || partPosition.positionXUnit === 'viewport') {
                if (parentResizeContext.parentSizeXUnit === 'pixels') {
                    x = (x * 100) / parentResizeContext.parentSizeX;
                } else {
                    x = (x * 100) / ScreenTypeEditorWidth.get(currentScreenType);
                }
            }
        }

        if (!!y) {
            if (partPosition.positionYUnit === 'grid') {
                const gridSizeY =
                    getSizeYForGridAmount(
                        1,
                        parentResizeContext.parentGrid,
                        parentResizeContext.parentElementHeight,
                        'pixels',
                        currentScreenType
                    ) + parentResizeContext.parentGrid.gridRowGapSize;
                y = Math.round(y / gridSizeY);
            } else if (partPosition.positionYUnit === 'percentage' || partPosition.positionYUnit === 'viewport') {
                if (parentResizeContext.parentSizeXUnit === 'pixels') {
                    y = (y * 100) / parentResizeContext.parentSizeY;
                } else {
                    y = (y * 100) / ScreenTypeEditorHeight.get(currentScreenType);
                }
            }
        }
    }
    return { x, y };
}

export function normalizeResizeResult(
    resizeResult: {
        width: number;
        height: number;
        top: number;
        left: number;
    },
    part: Part,
    partPositioning: PartPositioning,
    currentScreenType: number,
    currentViewScreenType: number,
    scale: number
) {
    const parentResizeContext = part.resizeContext.parentResizeContext;
    let top = resizeResult.top;
    let width = resizeResult.width;
    let height = resizeResult.height;
    let left = resizeResult.left;
    // LEFT
    if (!!left) {
        if (
            partPositioning.sizeXUnit === 'grid' ||
            partPositioning.sizeXUnit === 'grid-start' ||
            partPositioning.sizeXUnit === 'grid-end'
        ) {
            const gridSizeX =
                getSizeXForGridAmount(
                    1,
                    parentResizeContext.parentGrid,
                    parentResizeContext.parentElementWidth / scale,
                    'pixels',
                    currentViewScreenType
                ) + parentResizeContext.parentGrid.gridColumnGapSize;
            let remainder =
                ((part.resizeContext?.originalPartElementLeft - part.resizeContext.parentResizeContext.parentElementLeft) / scale) %
                gridSizeX;
            if (partPositioning.horizontalAlignment === 'right') {
                remainder = remainder * -1;
            }
            left = Math.round((left - remainder) / gridSizeX);
            if (partPositioning.positionXUnit === 'pixels') {
                left = left * gridSizeX + remainder;
            } else if (partPositioning.positionXUnit === 'percentage') {
                if (parentResizeContext.parentSizeXUnit === 'pixels') {
                    left = ((left * gridSizeX + remainder) * 100) / parentResizeContext.parentSizeX;
                } else {
                    left = ((left * gridSizeX + remainder) * 100) / ScreenTypeEditorWidth.get(currentViewScreenType);
                }
            } else if (partPositioning.positionXUnit === 'viewport') {
                left = ((left * gridSizeX + remainder) * 100) / window.innerWidth;
            }
        } else if (partPositioning.positionXUnit === 'percentage') {
            if (parentResizeContext.parentSizeXUnit === 'pixels') {
                left = (left * 100) / parentResizeContext.parentSizeX;
            } else {
                left = (left * 100) / ScreenTypeEditorWidth.get(currentViewScreenType);
            }
        } else if (partPositioning.positionXUnit === 'viewport') {
            left = (left * 100) / window.innerWidth;
        } else if (partPositioning.positionXUnit === 'grid') {
            const gridSizeX =
                getSizeXForGridAmount(
                    1,
                    parentResizeContext.parentGrid,
                    parentResizeContext.parentElementWidth / scale,
                    'pixels',
                    currentViewScreenType
                ) + parentResizeContext.parentGrid.gridColumnGapSize;
            left = Math.round(left / gridSizeX);
        }
    }

    // HEIGHT
    if (!!height) {
        if (partPositioning.sizeYUnit === 'percentage') {
            if (parentResizeContext.parentSizeYUnit === 'pixels') {
                height = (height * 100) / parentResizeContext.parentSizeY;
            } else {
                height = (height * 100) / ScreenTypeEditorHeight.get(currentViewScreenType);
            }
        }
        if (
            partPositioning.sizeYUnit === 'grid' ||
            partPositioning.sizeYUnit === 'grid-end' ||
            partPositioning.sizeYUnit === 'grid-start'
        ) {
            height = Math.round(
                height /
                    (getSizeYForGridAmount(
                        1,
                        parentResizeContext.parentGrid,
                        parentResizeContext.parentElementHeight / scale,
                        'pixels',
                        currentViewScreenType
                    ) +
                        parentResizeContext.parentGrid.gridRowGapSize)
            );
        }

        if (partPositioning.sizeYUnit === 'viewport') {
            height = (height * 100) / window.innerHeight;
        }
    }

    // WIDTH
    if (!!width) {
        if (partPositioning.sizeXUnit === 'percentage') {
            if (parentResizeContext.parentSizeXUnit === 'pixels') {
                width = (width * 100) / parentResizeContext.parentSizeX;
            } else {
                width = (width * 100) / ScreenTypeEditorWidth.get(currentViewScreenType);
            }
        }

        if (
            partPositioning.sizeXUnit === 'grid' ||
            partPositioning.sizeXUnit === 'grid-end' ||
            partPositioning.sizeXUnit === 'grid-start'
        ) {
            width = Math.round(
                width /
                    (getSizeXForGridAmount(
                        1,
                        parentResizeContext.parentGrid,
                        parentResizeContext.parentElementWidth / scale,
                        'pixels',
                        currentViewScreenType
                    ) +
                        parentResizeContext.parentGrid.gridColumnGapSize)
            );
        }

        if (partPositioning.sizeXUnit === 'viewport') {
            width = (width * 100) / window.innerWidth;
        }
    }

    // TOP
    if (!!top) {
        if (
            partPositioning.sizeYUnit === 'grid' ||
            partPositioning.sizeYUnit === 'grid-end' ||
            partPositioning.sizeYUnit === 'grid-start'
        ) {
            const gridSizeY =
                getSizeYForGridAmount(
                    1,
                    parentResizeContext.parentGrid,
                    parentResizeContext.parentElementHeight / scale,
                    'pixels',
                    currentViewScreenType
                ) + parentResizeContext.parentGrid.gridRowGapSize;
            let remainder =
                ((part.resizeContext?.originalPartElementTop - part.resizeContext.parentResizeContext.parentElementTop) / scale) %
                gridSizeY;
            if (partPositioning.verticalAlignment === 'bottom') {
                remainder = remainder * -1;
            }
            top = Math.round((top - remainder + parentResizeContext.parentGrid.gridRowGapSize) / gridSizeY);
            if (partPositioning.positionYUnit === 'pixels') {
                top = top * gridSizeY + remainder;
            } else if (partPositioning.positionYUnit === 'percentage') {
                if (parentResizeContext.parentSizeYUnit === 'pixels') {
                    top = ((top * gridSizeY + remainder) * 100) / parentResizeContext.parentSizeY;
                } else {
                    top = ((top * gridSizeY + remainder) * 100) / ScreenTypeEditorHeight.get(currentViewScreenType);
                }
            } else if (partPositioning.positionYUnit === 'viewport') {
                top = ((top * gridSizeY + remainder) * 100) / window.innerHeight;
            } else if (partPositioning.positionYUnit === 'grid') {
                top = Math.round(top / gridSizeY);
            }
        } else if (partPositioning.positionYUnit === 'percentage') {
            if (parentResizeContext.parentSizeYUnit === 'pixels') {
                top = (top * 100) / parentResizeContext.parentSizeY;
            } else {
                top = (top * 100) / ScreenTypeEditorHeight.get(currentViewScreenType);
            }
        } else if (partPositioning.positionYUnit === 'viewport') {
            top = (top * 100) / window.innerHeight;
        } else if (partPositioning.positionYUnit === 'grid') {
            const gridSizeY =
                getSizeYForGridAmount(
                    1,
                    parentResizeContext.parentGrid,
                    parentResizeContext.parentElementHeight / scale,
                    'pixels',
                    currentViewScreenType
                ) + parentResizeContext.parentGrid.gridRowGapSize;
            top = Math.round(top / gridSizeY);
        }
    }

    return { top, width, left, height };
}

export function resizePartAllUnits(
    type: string,
    pointerPositionX: number,
    pointerPositionY: number,
    scale: number,
    part: Part,
    currentScreenType: number | undefined,
    currentViewScreenType: number | undefined
): { width: number; height: number; top: number; left: number } {
    if (type === 'right-bottom') {
        return resizePartRightBottom(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'right') {
        return resizePartRight(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'bottom') {
        return resizePartBottom(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'top') {
        return resizePartTop(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'right-top') {
        return resizePartRightTop(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'left') {
        return resizePartLeft(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'left-top') {
        return resizePartLeftTop(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    } else if (type === 'left-bottom') {
        return resizePartLeftBottom(pointerPositionX, pointerPositionY, scale, part, currentScreenType, currentViewScreenType);
    }
    return null;
}

export function getDroppedPartPosition(
    part: Part,
    droppedX: number,
    droppedY: number,
    droppedPartSizeX: number,
    droppedPartSizeY: number,
    parentGrid: HTMLElement,
    ghostDrag: HTMLElement,
    mainGrid: HTMLElement,
    scale: number,
    grid: GridStyleDto,
    currentScreenType: number,
    currentViewScreenType: number
) {
    const halfWidthOfPart = ghostDrag.getBoundingClientRect().width / 2;
    const halfHeightOfPart = ghostDrag.getBoundingClientRect().height / 2;
    let distanceBetweenMiddleOfPartToLeftSideOfGrid = droppedX - parentGrid.getBoundingClientRect().left;
    let distanceBetweenMiddleOfPartToTopSideOfGrid = droppedY - parentGrid.getBoundingClientRect().top;
    if (parentGrid === mainGrid) {
        distanceBetweenMiddleOfPartToLeftSideOfGrid = distanceBetweenMiddleOfPartToLeftSideOfGrid + parentGrid.scrollLeft * scale;
        distanceBetweenMiddleOfPartToTopSideOfGrid = distanceBetweenMiddleOfPartToTopSideOfGrid + parentGrid.scrollTop * scale;
    } else {
        distanceBetweenMiddleOfPartToLeftSideOfGrid = distanceBetweenMiddleOfPartToLeftSideOfGrid + parentGrid.scrollLeft * scale;
        distanceBetweenMiddleOfPartToTopSideOfGrid = distanceBetweenMiddleOfPartToTopSideOfGrid + parentGrid.scrollTop * scale;
    }
    let partPosition = null;
    if (part) {
        partPosition = selectCurrentPartPositioningCreateNewIfNotExisting(currentScreenType, part.positions);
    }
    let newY;
    let newX;
    if (!!partPosition && partPosition.verticalAlignment === 'bottom') {
        const distanceBetweenBottomSideOfPartToBottomSideOfGrid =
            parentGrid.getBoundingClientRect().height - distanceBetweenMiddleOfPartToTopSideOfGrid - halfHeightOfPart;
        newY = Math.round(distanceBetweenBottomSideOfPartToBottomSideOfGrid / scale);
    } else {
        const distanceBetweenTopSideOfPartToTopSideOfGrid = distanceBetweenMiddleOfPartToTopSideOfGrid - halfHeightOfPart;
        newY = Math.round(distanceBetweenTopSideOfPartToTopSideOfGrid / scale);
    }

    if (!!partPosition && partPosition.horizontalAlignment === 'right') {
        const distanceBetweenRightSideOfPartToRightSideOfGrid =
            parentGrid.getBoundingClientRect().width - distanceBetweenMiddleOfPartToLeftSideOfGrid - halfWidthOfPart;
        newX = Math.round(distanceBetweenRightSideOfPartToRightSideOfGrid / scale);
    } else {
        const distanceBetweenLeftSideOfPartToRightSideOfGrid = distanceBetweenMiddleOfPartToLeftSideOfGrid - halfWidthOfPart;
        newX = Math.round(distanceBetweenLeftSideOfPartToRightSideOfGrid / scale);
    }
    const normalizeResult = normalizeMoveResult({ x: newX, y: newY }, part, partPosition, currentViewScreenType);
    return { x: normalizeResult.x, y: normalizeResult.y };
}

declare let jQuery: any;

export function moveDrag(event, scale) {
    if (event) {
        event.preventDefault();
        const gridDropList = jQuery('#grid-drop-list').get(0);
        const ghostDrag = jQuery('#ghost-drag').get(0);
        const top = Math.round(
            (event.y -
                gridDropList.getBoundingClientRect().top +
                gridDropList.scrollTop * scale -
                ghostDrag.getBoundingClientRect().height / 2) /
                scale
        );
        const left = Math.round(
            (event.x -
                gridDropList.getBoundingClientRect().left +
                gridDropList.scrollLeft * scale -
                ghostDrag.getBoundingClientRect().width / 2) /
                scale
        );
        jQuery('#ghost-drag').css({
            top: `${top}px`,
            left: `${left}px`,
        });
    }
}

export function changePosition($event: PositionChanged, part: Part) {
    part.positions = $event.partPositionings;
}

export function changeSize($event: SizeChanged, part: Part) {
    part.positions = $event.partPositionings;
}

export function getPartPositioningByNumber(part: Part, screenType: number | undefined) {
    if (screenType && part) {
        return part?.positions?.find(partPosition => partPosition.screenType === ScreenType[screenType]);
    } else {
        return undefined;
    }
}

export function getPartPosition(part: Part, partPositioning: PartPositioning, grid: GridStyleDto) {
    let bottom;
    let top;
    let right;
    let left;
    let zIndex;
    let width;
    let height;
    let minWidth;
    let minHeight;
    let maxWidth;
    let maxHeight;
    let grow;
    if (partPositioning.verticalAlignment === 'bottom') {
        bottom = part.getVerticalPositioning(partPositioning, grid);
        top = undefined;
    } else {
        top = part.getVerticalPositioning(partPositioning, grid);
        bottom = undefined;
    }
    if (partPositioning.horizontalAlignment === 'right') {
        right = part.getHorizontalPositioning(partPositioning, grid);
        left = undefined;
    } else {
        left = part.getHorizontalPositioning(partPositioning, grid);
        right = undefined;
    }
    zIndex = part.getZIndex(partPositioning);
    width = part.getWidthFromPositioning(partPositioning, false, grid);
    height = part.getHeightFromPositioning(partPositioning, false, grid);
    minWidth = part.getMinWidthFromPositioning(partPositioning, false, grid);
    minHeight = part.getMinHeightFromPositioning(partPositioning, false, grid);
    maxWidth = part.getMaxWidthFromPositioning(partPositioning, false, grid);
    maxHeight = part.getMaxHeightFromPositioning(partPositioning, false, grid);
    grow = partPositioning.grow;
    return { bottom, top, left, right, zIndex, height, width, minWidth, maxWidth, minHeight, maxHeight, grow };
}
