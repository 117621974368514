import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { GenericStyleV2Dto } from '../../../../../interfaces/style/generic-style-v2.dto';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';

export interface SliderFieldStyle extends GenericStyleV2Dto {
    labelFontStyle: FontStyleDto;
    labelMarginStyle: GenericMarginStyleDto;

    sliderColor: string;
}
