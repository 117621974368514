import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PartPositioning, PositionChanged, selectCurrentPartPositioning, TemplateEditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-position-picker',
    templateUrl: './position-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionPickerComponent implements OnDestroy {
    @Output()
    changePosition: EventEmitter<PositionChanged> = new EventEmitter();

    @Input()
    parentType: string;

    subscriptions: Subscription = new Subscription();

    partPositioningData$: Observable<{
        currentPartPositioning: PartPositioning;
        partPositions: PartPositioning[];
    }> = combineLatest([this.templateEditorFacade.currentScreenType, this.templateEditorFacade.selectedPart]).pipe(
        filter(([currentScreenType, selectedPart]) => (!!currentScreenType || currentScreenType === 0) && !!selectedPart),
        map(([currentScreenType, selectedPart]) => {
            return {
                currentPartPositioning: selectCurrentPartPositioning(currentScreenType, selectedPart.positions),
                partPositions: selectedPart.positions,
            };
        })
    );

    constructor(
        private templateEditorFacade: TemplateEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges() {
        this.changeDetectorRef.detectChanges();
    }
    onChangeX($event, partPositioning: PartPositioning) {
        if ($event.viewPort != null) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.x = $event.viewPort;
                    partPositioning.positionXUnit = $event.unit;
                    this.onChangePosition(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    responseSettingsForY(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.y || partPositioning.y === 0) && partPositioning.positionXUnit) {
                if (
                    partPositioning.y !== partPositioningData.currentPartPositioning.y ||
                    partPositioning.positionYUnit !== partPositioningData.currentPartPositioning.positionYUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.y || partPositioningData.currentPartPositioning.y === 0) &&
                        !!partPositioningData.currentPartPositioning.positionYUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForX(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.x || partPositioning.x === 0) && partPositioning.positionXUnit) {
                if (
                    partPositioning.x !== partPositioningData.currentPartPositioning.x ||
                    partPositioning.positionYUnit !== partPositioningData.currentPartPositioning.positionYUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.x || partPositioningData.currentPartPositioning.x === 0) &&
                        !!partPositioningData.currentPartPositioning.positionYUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    onChangeY($event, partPositioning: PartPositioning) {
        if ($event.viewPort != null) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.y = $event.viewPort;
                    partPositioning.positionYUnit = $event.unit;
                    this.onChangePosition(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeFixedPosition() {
        this.subscriptions.add(
            this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                this.onChangePosition(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
            })
        );
    }

    onChangeHidden() {
        this.subscriptions.add(
            this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                this.onChangePosition(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
            })
        );
    }

    onChangeVerticalAlignment($event: string) {
        this.subscriptions.add(
            this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                partPositioningData.currentPartPositioning.verticalAlignment = $event;
                this.onChangePosition(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
            })
        );
    }

    onChangeZIndex($event: number) {
        this.subscriptions.add(
            this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                partPositioningData.currentPartPositioning.zIndex = $event;
                this.onChangePosition(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
            })
        );
    }

    onChangeHorizontalAlignment($event: string) {
        this.subscriptions.add(
            this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                partPositioningData.currentPartPositioning.horizontalAlignment = $event;
                this.onChangePosition(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
            })
        );
    }

    onChangePosition(partPositioning: PartPositioning, existingPartPositionings: PartPositioning[]) {
        const foundPartPositioning = existingPartPositionings.find(
            existingPartPositioning => existingPartPositioning.screenType === partPositioning.screenType
        );

        if (!foundPartPositioning) {
            existingPartPositionings.push(partPositioning);
        } else {
            foundPartPositioning.x = partPositioning.x;
            foundPartPositioning.y = partPositioning.y;
            foundPartPositioning.positionYUnit = partPositioning.positionYUnit;
            foundPartPositioning.positionXUnit = partPositioning.positionXUnit;
            foundPartPositioning.horizontalAlignment = partPositioning.horizontalAlignment;
            foundPartPositioning.verticalAlignment = partPositioning.verticalAlignment;
            foundPartPositioning.fixedPosition = partPositioning.fixedPosition;
            foundPartPositioning.hidden = partPositioning.hidden;
            foundPartPositioning.zIndex = partPositioning.zIndex;
        }
        this.changePosition.emit({
            partPositionings: existingPartPositionings,
        });
    }

    identifyPartPositioning(index, item: PartPositioning) {
        return item.id;
    }
}
