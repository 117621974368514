<ng-container *ngIf="{ partPositioningData: partPositioningData$ | async } as observables">
    <div class="flex flex-col" *ngIf="observables.partPositioningData?.currentPartPositioning">
        <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.style.position' | translate }}</h4>
        <div class="flex flex-row mb-2 justify-between gap-2">
            <div class="button-group" role="group">
                <button
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-horizontal-alignment.left' | translate"
                    (click)="onChangeHorizontalAlignment('left')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.horizontalAlignment === 'left'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_horizontal_left</mat-icon>
                </button>
                <button
                    type="button"
                    class="button-group-button-right"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-horizontal-alignment.right' | translate"
                    (click)="onChangeHorizontalAlignment('right')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.horizontalAlignment === 'right'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_horizontal_right</mat-icon>
                </button>
            </div>
            <div class="button-group" role="group">
                <button
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-vertical-alignment.top' | translate"
                    (click)="onChangeVerticalAlignment('top')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.verticalAlignment === 'top'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_vertical_top</mat-icon>
                </button>
                <button
                    type="button"
                    class="button-group-button-right"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-vertical-alignment.bottom' | translate"
                    (click)="onChangeVerticalAlignment('bottom')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.verticalAlignment === 'bottom'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_vertical_bottom</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForX(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.x"
                [unit]="observables.partPositioningData.currentPartPositioning.positionXUnit"
                (viewPortChange)="onChangeX($event, observables.partPositioningData.currentPartPositioning)"
                [allowedUnits]="['pixels', 'percentage', 'viewport', 'grid']"
                label="X">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.x"
                                [unit]="partPositioning.positionXUnit"
                                label="X"
                                (viewPortChange)="onChangeX($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForY(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.y"
                [unit]="observables.partPositioningData.currentPartPositioning.positionYUnit"
                (viewPortChange)="onChangeY($event, observables.partPositioningData.currentPartPositioning)"
                [allowedUnits]="['pixels', 'percentage', 'viewport', 'grid']"
                label="Y">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.y"
                                [unit]="partPositioning.positionYUnit"
                                label="Y"
                                (viewPortChange)="onChangeY($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
        </div>

        <div class="small-number-wrapper">
            <label [attr.for]="'z-index-'" class="small-input-label">
                {{ 'v2.part.style.position.zindex' | translate }}
            </label>
            <input
                [attr.id]="'z-index-'"
                class="small-input small-number-input"
                required
                type="number"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.zIndex"
                (ngModelChange)="onChangeZIndex($event)" />
        </div>

        <label class="toggle-line">
            {{ 'v2.style.position.fixed' | translate }}
            <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.fixedPosition"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onChangeFixedPosition()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line">
            {{ 'v2.style.position.hidden' | translate }}
            <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.hidden"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onChangeHidden()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>
    </div>
</ng-container>
