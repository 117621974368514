import { FontUrlsDto } from './font.urls.dto';
import { PartStyle } from '../../models/template/part-style.model';
import { Color } from '../../designsystem/models/color/color.model';
import { generateColorStyle, generateColorStyleV2 } from './color.style';
import { generatePartStyleReferenceCss, PartStyleReferenceDto, SelectorId } from './part-style-reference.dto';
import { ColorV2 } from '../../designsystem/models/color/colorv2.model';

const UNITS = new Map([
    ['pixels', { value: 'pixels', display: 'px' }],
    ['rem', { value: 'rem', display: 'rem' }],
    ['em', { value: 'em', display: 'em' }],
    ['percentage', { value: 'percentage', display: '%' }],
    ['viewport', { value: 'viewport', display: 'vp' }],
    ['grid', { value: 'grid', display: 'grid' }],
    ['unset', { value: 'unset', display: 'unset' }],
    ['fit-content', { value: 'fit-content', display: 'fit-content' }],
]);

export function getUnitCSS(nocodexValue: string): string | undefined {
    return UNITS?.get(nocodexValue)?.display;
}

export interface FontStyleDto {
    //deprecated
    fontFamily?: string;
    family?: PartStyleReferenceDto;
    fontUrls?: FontUrlsDto;
    //deprecated
    letterSpacingValue?: number;
    //deprecated
    letterSpacingUnit?: string;
    letterSpacing?: PartStyleReferenceDto;
    //deprecated
    fontSizeValue?: number;
    //deprecated
    fontSizeUnit?: string;
    //deprecated
    fontSize?: string;
    size?: PartStyleReferenceDto;
    //deprecated
    fontWeight?: string;
    weight?: PartStyleReferenceDto;
    fontStretch?: string;
    //deprecated
    fontStyle?: string;
    style?: PartStyleReferenceDto;
    fontVariant?: string;
    fontVariantCaps?: string;
    fontColor?: string;
    //deprecated
    color?: Color;
    colorV2?: ColorV2;
    fontAlignment?: string;
    lineHeight?: string;
    lineHeightValue?: number;
    lineHeightUnit?: string;
    typographySelectorId?: SelectorId;

    textDecorationLine?: string;
    textDecorationStyle?: string;
    textDecorationThickness?: PartStyleReferenceDto;
    textDecorationColor?: ColorV2;
}

export function generateFontAlignment(fontAlignment: string) {
    let style = '';
    if (fontAlignment) {
        style += 'text-align:' + fontAlignment + ';';
    }
    return style;
}

export function generateFontSize(fontSize: string) {
    let style = '';
    if (fontSize) {
        style += 'font-size:' + fontSize + ';';
    }
    return style;
}

export function generateFontColor(color: ColorV2 | null | undefined) {
    let style = '';
    if (color) {
        style += 'color:' + generateColorStyleV2(color) + ';';
    }
    return style;
}

export function generateFontStyle(partStyle: PartStyle) {
    let style = '';
    if (partStyle['fontStyleDto']) {
        style += generateTypeFontStyle(partStyle['fontStyleDto']);
    }
    return style;
}

export function generateInputLabelFontStyle(partId: string, status: string, fontStyle: FontStyleDto): string {
    let style = '';
    if (fontStyle) {
        if (status === '' || status === ':hover') {
            style += '#id-' + partId + ' .mat-form-field label {';
            style += generateTypeFontStyle(fontStyle);
            style += '}';
        } else if (status === ':focus') {
            style += '#id-' + partId + ' .mat-form-field.mat-focused label {';
            style += generateTypeFontStyle(fontStyle);
            style += '}';
        }
    }

    return style;
}

export function generateTypeFontStyleWithVars(
    fontStyle: FontStyleDto,
    fontFamilyVar: string,
    fontSizeVar: string,
    fontWeightVar: string,
    fontStretchVar: string,
    fontStyleVar: string,
    fontVariantVar: string,
    fontVariantCapsVar: string,
    fontColorVar: string,
    textAlignVar: string,
    lineHeightVar: string,
    letterSpacingVar: string
) {
    let style = '';
    if (fontStyle) {
        if (fontStyle.family) {
            // DEPRECATED
            style += fontFamilyVar + ':' + generatePartStyleReferenceCss(fontStyle.family) + ';';
        } else if (fontStyle.fontFamily) {
            style += fontFamilyVar + ':' + fontStyle.fontFamily + ';';
        }

        if (fontStyle.size) {
            style += fontSizeVar + ':' + generatePartStyleReferenceCss(fontStyle.size) + ';';
        } else if (fontStyle.fontSizeValue && fontStyle.fontSizeUnit) {
            // DEPRECATED
            style += fontSizeVar + ':' + fontStyle.fontSizeValue + getUnitCSS(fontStyle.fontSizeUnit) + ';';
        } else if (fontStyle.fontSize) {
            //DEPRECATED
            style += fontSizeVar + ':' + fontStyle.fontSize + ';';
        }

        if (fontStyle.weight) {
            // DEPRECATED
            style += fontWeightVar + ':' + generatePartStyleReferenceCss(fontStyle.weight) + ';';
        } else if (fontStyle.fontWeight) {
            style += fontWeightVar + ':' + fontStyle.fontWeight + ';';
        }

        if (fontStyle.fontStretch) {
            style += fontStretchVar + ':' + fontStyle.fontStretch + ';';
        }

        if (fontStyle.fontStyle && fontStyle.fontStyle !== 'regular') {
            style += fontStyleVar + ':' + fontStyle.fontStyle + ';';
        }

        if (fontStyle.fontVariant) {
            style += fontVariantVar + ':' + fontStyle.fontVariant + ';';
        }

        if (fontStyle.fontVariantCaps) {
            style += fontVariantCapsVar + ':' + fontStyle.fontVariantCaps + ';';
        }

        if (fontStyle.colorV2) {
            style += fontColorVar + ':' + generateColorStyleV2(fontStyle.colorV2) + ';';
        } else if (fontStyle.color) {
            style += fontColorVar + ':' + generateColorStyle(fontStyle.color) + ';';
        }

        if (fontStyle.fontAlignment) {
            style += textAlignVar + ':' + fontStyle.fontAlignment + ';';
        }

        if (fontStyle.lineHeightValue && fontStyle.lineHeightUnit) {
            style += lineHeightVar + ':' + fontStyle.lineHeightValue + getUnitCSS(fontStyle.lineHeightUnit) + ';';
        } else if (fontStyle.lineHeight) {
            style += lineHeightVar + ':' + fontStyle.lineHeight + ';';
        }

        if (fontStyle.letterSpacing) {
            // DEPRECATED
            style += letterSpacingVar + ':' + generatePartStyleReferenceCss(fontStyle.letterSpacing) + ';';
        } else if (fontStyle.letterSpacingValue && fontStyle.letterSpacingUnit) {
            style += letterSpacingVar + ':' + fontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }
    }
    return style;
}

export function generateInputTypeFont(fontStyle: FontStyleDto, hoverFontStyle: FontStyleDto, focusFontStyle: FontStyleDto) {
    let style = '';
    if (fontStyle) {
        if (fontStyle?.fontFamily) {
            style += '--input-font-family:' + fontStyle.fontFamily + ';';
        }
        if (hoverFontStyle?.fontFamily) {
            style += '--input-hover-font-family:' + hoverFontStyle.fontFamily + ';';
        }
        if (focusFontStyle?.fontFamily) {
            style += '--input-focus-font-family:' + fontStyle.fontFamily + ';';
        }

        if (fontStyle?.fontSizeValue && fontStyle?.fontSizeUnit) {
            style += '--input-font-size:' + fontStyle.fontSizeValue + getUnitCSS(fontStyle.fontSizeUnit) + ';';
        } else if (fontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-font-size:' + fontStyle.fontSize + ';';
        }

        if (hoverFontStyle?.fontSizeValue && hoverFontStyle?.fontSizeUnit) {
            style += '--input-hover-font-size:' + hoverFontStyle.fontSizeValue + getUnitCSS(hoverFontStyle.fontSizeUnit) + ';';
        } else if (hoverFontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-hover-font-size:' + hoverFontStyle.fontSize + ';';
        }

        if (focusFontStyle?.fontSizeValue && focusFontStyle?.fontSizeUnit) {
            style += '--input-focus-font-size:' + focusFontStyle.fontSizeValue + getUnitCSS(focusFontStyle.fontSizeUnit) + ';';
        } else if (focusFontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-focus-font-size:' + focusFontStyle.fontSize + ';';
        }

        if (fontStyle?.fontWeight) {
            style += '--input-font-weight:' + fontStyle.fontWeight + ';';
        }

        if (hoverFontStyle?.fontWeight) {
            style += '--input-hover-font-weight:' + hoverFontStyle.fontWeight + ';';
        }

        if (focusFontStyle?.fontWeight) {
            style += '--input-focus-font-weight:' + focusFontStyle.fontWeight + ';';
        }

        if (fontStyle?.fontStretch) {
            style += '--input-font-stretch:' + fontStyle.fontStretch + ';';
        }
        if (hoverFontStyle?.fontStretch) {
            style += '--input-hover-font-stretch:' + hoverFontStyle.fontStretch + ';';
        }
        if (focusFontStyle?.fontStretch) {
            style += '--input-focus-font-stretch:' + focusFontStyle.fontStretch + ';';
        }

        if (fontStyle?.fontStyle && fontStyle?.fontStyle !== 'regular') {
            style += '--input-font-style:' + fontStyle.fontStyle + ';';
        }
        if (hoverFontStyle?.fontStyle && hoverFontStyle?.fontStyle !== 'regular') {
            style += '--input-hover-font-style:' + hoverFontStyle.fontStyle + ';';
        }
        if (focusFontStyle?.fontStyle && focusFontStyle?.fontStyle !== 'regular') {
            style += '--input-focus-font-style:' + focusFontStyle.fontStyle + ';';
        }

        if (fontStyle?.fontVariant) {
            style += '--input-font-variant:' + fontStyle.fontVariant + ';';
        }
        if (hoverFontStyle?.fontVariant) {
            style += '--input-hover-font-variant:' + hoverFontStyle.fontVariant + ';';
        }
        if (focusFontStyle?.fontVariant) {
            style += '--input-focus-font-variant:' + focusFontStyle.fontVariant + ';';
        }

        if (fontStyle?.fontVariantCaps) {
            style += '--input-font-variant-caps:' + fontStyle.fontVariantCaps + ';';
        }
        if (hoverFontStyle?.fontVariantCaps) {
            style += '--input-hover-font-variant-caps:' + hoverFontStyle.fontVariantCaps + ';';
        }
        if (focusFontStyle?.fontVariantCaps) {
            style += '--input-focus-font-variant-caps:' + focusFontStyle.fontVariantCaps + ';';
        }

        if (fontStyle?.color) {
            style += '--input-font-color:' + generateColorStyle(fontStyle.color) + ';';
        }
        if (hoverFontStyle?.color) {
            style += '--input-hover-font-color:' + generateColorStyle(hoverFontStyle.color) + ';';
        }
        if (focusFontStyle?.color) {
            style += '--input-focus-font-color:' + generateColorStyle(focusFontStyle.color) + ';';
        }

        if (fontStyle?.fontAlignment) {
            style += '--input-font-alignment:' + fontStyle.fontAlignment + ';';
        }
        if (hoverFontStyle?.fontAlignment) {
            style += '--input-hover-font-alignment:' + hoverFontStyle.fontAlignment + ';';
        }
        if (focusFontStyle?.fontAlignment) {
            style += '--input-focus-font-alignment:' + focusFontStyle.fontAlignment + ';';
        }

        if (fontStyle?.lineHeightValue && fontStyle?.lineHeightUnit) {
            style += '--input-font-line-height:' + fontStyle.lineHeightValue + getUnitCSS(fontStyle.lineHeightUnit) + ';';
        } else if (fontStyle?.lineHeight) {
            style += '--input-font-line-height:' + fontStyle.lineHeight + ';';
        }
        if (hoverFontStyle?.lineHeightValue && hoverFontStyle?.lineHeightUnit) {
            style += '--input-hover-font-line-height:' + hoverFontStyle.lineHeightValue + getUnitCSS(hoverFontStyle.lineHeightUnit) + ';';
        } else if (hoverFontStyle?.lineHeight) {
            style += '--input-hover-font-line-height:' + hoverFontStyle.lineHeight + ';';
        }
        if (focusFontStyle?.lineHeightValue && focusFontStyle?.lineHeightUnit) {
            style += '--input-focus-font-line-height:' + focusFontStyle.lineHeightValue + getUnitCSS(focusFontStyle.lineHeightUnit) + ';';
        } else if (fontStyle?.lineHeight) {
            style += '--input-focus-font-line-height:' + focusFontStyle.lineHeight + ';';
        }

        if (fontStyle?.letterSpacingValue && fontStyle?.letterSpacingUnit) {
            style += '--input-font-letter-spacing:' + fontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }
        if (hoverFontStyle?.letterSpacingValue && hoverFontStyle?.letterSpacingUnit) {
            style +=
                '--input-hover-font-letter-spacing:' + hoverFontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }
        if (focusFontStyle?.letterSpacingValue && focusFontStyle?.letterSpacingUnit) {
            style +=
                '--input-focus-font-letter-spacing:' +
                focusFontStyle.letterSpacingValue +
                getUnitCSS(focusFontStyle.letterSpacingUnit) +
                ';';
        }
    }
    return style;
}

export function generateInputLabelTypeFont(fontStyle: FontStyleDto, hoverFontStyle: FontStyleDto, focusFontStyle: FontStyleDto) {
    let style = '';
    if (fontStyle) {
        if (fontStyle?.fontFamily) {
            style += '--input-label-font-family:' + fontStyle.fontFamily + ';';
        }
        if (hoverFontStyle?.fontFamily) {
            style += '--input-label-hover-font-family:' + hoverFontStyle.fontFamily + ';';
        }
        if (focusFontStyle?.fontFamily) {
            style += '--input-label-focus-font-family:' + fontStyle.fontFamily + ';';
        }

        if (fontStyle?.fontSizeValue && fontStyle?.fontSizeUnit) {
            style += '--input-label-font-size:' + fontStyle.fontSizeValue + getUnitCSS(fontStyle.fontSizeUnit) + ';';
        } else if (fontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-label-font-size:' + fontStyle.fontSize + ';';
        }

        if (hoverFontStyle?.fontSizeValue && hoverFontStyle?.fontSizeUnit) {
            style += '--input-label-hover-font-size:' + hoverFontStyle.fontSizeValue + getUnitCSS(hoverFontStyle.fontSizeUnit) + ';';
        } else if (hoverFontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-label-hover-font-size:' + hoverFontStyle.fontSize + ';';
        }

        if (focusFontStyle?.fontSizeValue && focusFontStyle?.fontSizeUnit) {
            style += '--input-label-focus-font-size:' + focusFontStyle.fontSizeValue + getUnitCSS(focusFontStyle.fontSizeUnit) + ';';
        } else if (focusFontStyle?.fontSize) {
            //DEPRECATED
            style += '--input-label-focus-font-size:' + focusFontStyle.fontSize + ';';
        }

        if (fontStyle?.fontWeight) {
            style += '--input-label-font-weight:' + fontStyle.fontWeight + ';';
        }

        if (hoverFontStyle?.fontWeight) {
            style += '--input-label-hover-font-weight:' + hoverFontStyle.fontWeight + ';';
        }

        if (focusFontStyle?.fontWeight) {
            style += '--input-label-focus-font-weight:' + focusFontStyle.fontWeight + ';';
        }

        if (fontStyle?.fontStretch) {
            style += '--input-label-font-stretch:' + fontStyle.fontStretch + ';';
        }
        if (hoverFontStyle?.fontStretch) {
            style += '--input-label-hover-font-stretch:' + hoverFontStyle.fontStretch + ';';
        }
        if (focusFontStyle?.fontStretch) {
            style += '--input-label-focus-font-stretch:' + focusFontStyle.fontStretch + ';';
        }

        if (fontStyle?.fontStyle && fontStyle?.fontStyle !== 'regular') {
            style += '--input-label-font-style:' + fontStyle.fontStyle + ';';
        }
        if (hoverFontStyle?.fontStyle && hoverFontStyle?.fontStyle !== 'regular') {
            style += '--input-label-hover-font-style:' + hoverFontStyle.fontStyle + ';';
        }
        if (focusFontStyle?.fontStyle && focusFontStyle?.fontStyle !== 'regular') {
            style += '--input-label-focus-font-style:' + focusFontStyle.fontStyle + ';';
        }

        if (fontStyle?.fontVariant) {
            style += '--input-label-font-variant:' + fontStyle.fontVariant + ';';
        }
        if (hoverFontStyle?.fontVariant) {
            style += '--input-label-hover-font-variant:' + hoverFontStyle.fontVariant + ';';
        }
        if (focusFontStyle?.fontVariant) {
            style += '--input-label-focus-font-variant:' + focusFontStyle.fontVariant + ';';
        }

        if (fontStyle?.fontVariantCaps) {
            style += '--input-label-font-variant-caps:' + fontStyle.fontVariantCaps + ';';
        }
        if (hoverFontStyle?.fontVariantCaps) {
            style += '--input-label-hover-font-variant-caps:' + hoverFontStyle.fontVariantCaps + ';';
        }
        if (focusFontStyle?.fontVariantCaps) {
            style += '--input-label-focus-font-variant-caps:' + focusFontStyle.fontVariantCaps + ';';
        }

        if (fontStyle?.color) {
            style += '--input-label-font-color:' + generateColorStyle(fontStyle.color) + ';';
        }
        if (hoverFontStyle?.color) {
            style += '--input-label-hover-font-color:' + generateColorStyle(hoverFontStyle.color) + ';';
        }
        if (focusFontStyle?.color) {
            style += '--input-label-focus-font-color:' + generateColorStyle(focusFontStyle.color) + ';';
        }

        if (fontStyle?.fontAlignment) {
            style += '--input-label-font-alignment:' + fontStyle.fontAlignment + ';';
        }
        if (hoverFontStyle?.fontAlignment) {
            style += '--input-label-hover-font-alignment:' + hoverFontStyle.fontAlignment + ';';
        }
        if (focusFontStyle?.fontAlignment) {
            style += '--input-label-focus-font-alignment:' + focusFontStyle.fontAlignment + ';';
        }

        if (fontStyle?.lineHeightValue && fontStyle?.lineHeightUnit) {
            style += '--input-label-font-line-height:' + fontStyle.lineHeightValue + getUnitCSS(fontStyle.lineHeightUnit) + ';';
        } else if (fontStyle?.lineHeight) {
            style += '--input-label-font-line-height:' + fontStyle.lineHeight + ';';
        }
        if (hoverFontStyle?.lineHeightValue && hoverFontStyle?.lineHeightUnit) {
            style +=
                '--input-label-hover-font-line-height:' + hoverFontStyle.lineHeightValue + getUnitCSS(hoverFontStyle.lineHeightUnit) + ';';
        } else if (hoverFontStyle?.lineHeight) {
            style += '--input-label-hover-font-line-height:' + hoverFontStyle.lineHeight + ';';
        }
        if (focusFontStyle?.lineHeightValue && focusFontStyle?.lineHeightUnit) {
            style +=
                '--input-label-focus-font-line-height:' + focusFontStyle.lineHeightValue + getUnitCSS(focusFontStyle.lineHeightUnit) + ';';
        } else if (fontStyle?.lineHeight) {
            style += '--input-label-focus-font-line-height:' + focusFontStyle.lineHeight + ';';
        }

        if (fontStyle?.letterSpacingValue && fontStyle?.letterSpacingUnit) {
            style += '--input-label-font-letter-spacing:' + fontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }
        if (hoverFontStyle?.letterSpacingValue && hoverFontStyle?.letterSpacingUnit) {
            style +=
                '--input-label-hover-font-letter-spacing:' +
                hoverFontStyle.letterSpacingValue +
                getUnitCSS(fontStyle.letterSpacingUnit) +
                ';';
        }
        if (focusFontStyle?.letterSpacingValue && focusFontStyle?.letterSpacingUnit) {
            style +=
                '--input-label-focus-font-letter-spacing:' +
                focusFontStyle.letterSpacingValue +
                getUnitCSS(focusFontStyle.letterSpacingUnit) +
                ';';
        }
    }
    return style;
}

export function generateTypeFontStyle(fontStyle: FontStyleDto) {
    let style = '';
    if (fontStyle) {
        if (fontStyle.family) {
            style += 'font-family:' + generatePartStyleReferenceCss(fontStyle.family) + ';';
        } else if (fontStyle.fontFamily) {
            style += 'font-family:' + fontStyle.fontFamily + ';';
        }

        if (fontStyle.size) {
            style += 'font-size:' + generatePartStyleReferenceCss(fontStyle.size) + ';';
        } else if (fontStyle.fontSizeValue && fontStyle.fontSizeUnit) {
            style += 'font-size:' + fontStyle.fontSizeValue + getUnitCSS(fontStyle.fontSizeUnit) + ';';
        } else if (fontStyle.fontSize) {
            //DEPRECATED
            style += 'font-size:' + fontStyle.fontSize + ';';
        }

        if (fontStyle.weight) {
            style += 'font-weight:' + generatePartStyleReferenceCss(fontStyle.weight) + ';';
        } else if (fontStyle.fontWeight) {
            style += 'font-weight:' + fontStyle.fontWeight + ';';
        }

        if (fontStyle.fontStretch) {
            style += 'font-stretch:' + fontStyle.fontStretch + ';';
        }

        if (fontStyle.fontStyle && fontStyle.fontStyle !== 'regular') {
            style += 'font-style:' + fontStyle.fontStyle + ';';
        }

        if (fontStyle.fontVariant) {
            style += 'font-variant:' + fontStyle.fontVariant + ';';
        }

        if (fontStyle.fontVariantCaps) {
            style += 'font-variant-caps:' + fontStyle.fontVariantCaps + ';';
        }

        if (fontStyle.colorV2) {
            style += 'color:' + generateColorStyleV2(fontStyle.colorV2) + ';';
        } else if (fontStyle.color) {
            style += 'color:' + generateColorStyle(fontStyle.color) + ';';
        }

        if (fontStyle.fontAlignment) {
            style += 'text-align:' + fontStyle.fontAlignment + ';';
        }

        if (fontStyle.lineHeightValue && fontStyle.lineHeightUnit) {
            style += 'line-height:' + fontStyle.lineHeightValue + getUnitCSS(fontStyle.lineHeightUnit) + ';';
        } else if (fontStyle.lineHeight) {
            style += 'line-height:' + fontStyle.lineHeight + ';';
        }

        if (fontStyle.letterSpacing) {
            style += 'letter-spacing:' + generatePartStyleReferenceCss(fontStyle.letterSpacing) + ';';
        } else if (fontStyle.letterSpacingValue && fontStyle.letterSpacingUnit) {
            style += 'letter-spacing:' + fontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }
    }
    return style;
}

export function generateTypeFontStyleWithVarsV2(prefix: string | undefined, fontStyle: FontStyleDto) {
    let style = '';
    if (fontStyle) {
        if (fontStyle.family) {
            style += '--' + prefix + 'font-family:' + generatePartStyleReferenceCss(fontStyle.family) + ';';
        } else if (fontStyle.fontFamily) {
            style += '--' + prefix + 'font-family:' + fontStyle.fontFamily + ';';
        }

        if (fontStyle.size) {
            style += '--' + prefix + 'font-size:' + generatePartStyleReferenceCss(fontStyle.size) + ';';
        } else if (fontStyle.fontSizeValue && fontStyle.fontSizeUnit) {
            style += '--' + prefix + 'font-size:' + fontStyle.fontSizeValue + getUnitCSS(fontStyle.fontSizeUnit) + ';';
        } else if (fontStyle.fontSize) {
            //DEPRECATED
            style += '--' + prefix + 'font-size:' + fontStyle.fontSize + ';';
        }

        if (fontStyle.weight) {
            style += '--' + prefix + 'font-weight:' + generatePartStyleReferenceCss(fontStyle.weight) + ';';
        } else if (fontStyle.fontWeight) {
            style += '--' + prefix + 'font-weight:' + fontStyle.fontWeight + ';';
        }

        if (fontStyle.fontStretch) {
            style += '--' + prefix + 'font-stretch:' + fontStyle.fontStretch + ';';
        }

        if (fontStyle.fontStyle && fontStyle.fontStyle !== 'regular') {
            style += '--' + prefix + 'font-style:' + fontStyle.fontStyle + ';';
        }

        if (fontStyle.fontVariant) {
            style += '--' + prefix + 'font-variant:' + fontStyle.fontVariant + ';';
        }

        if (fontStyle.fontVariantCaps) {
            style += '--' + prefix + 'font-variant-caps:' + fontStyle.fontVariantCaps + ';';
        }

        if (fontStyle.colorV2) {
            style += '--' + prefix + 'color:' + generateColorStyleV2(fontStyle.colorV2) + ';';
        } else if (fontStyle.color) {
            style += '--' + prefix + 'color:' + generateColorStyle(fontStyle.color) + ';';
        }

        if (fontStyle.fontAlignment) {
            style += '--' + prefix + 'text-align:' + fontStyle.fontAlignment + ';';
        }

        if (fontStyle.lineHeightValue && fontStyle.lineHeightUnit) {
            style += '--' + prefix + 'line-height:' + fontStyle.lineHeightValue + getUnitCSS(fontStyle.lineHeightUnit) + ';';
        } else if (fontStyle.lineHeight) {
            style += '--' + prefix + 'line-height:' + fontStyle.lineHeight + ';';
        }

        if (fontStyle.letterSpacing) {
            style += '--' + prefix + 'letter-spacing:' + generatePartStyleReferenceCss(fontStyle.letterSpacing) + ';';
        } else if (fontStyle.letterSpacingValue && fontStyle.letterSpacingUnit) {
            style += '--' + prefix + 'letter-spacing:' + fontStyle.letterSpacingValue + getUnitCSS(fontStyle.letterSpacingUnit) + ';';
        }

        if (fontStyle.textDecorationStyle) {
            style += '--' + prefix + 'text-decoration-style:' + fontStyle.textDecorationStyle + ';';
        }

        if (fontStyle.textDecorationLine) {
            style += '--' + prefix + 'text-decoration-line:' + fontStyle.textDecorationLine + ';';
        }

        if (fontStyle.textDecorationColor) {
            style += '--' + prefix + 'text-decoration-color:' + generateColorStyleV2(fontStyle.textDecorationColor) + ';';
        }

        if (fontStyle.textDecorationThickness) {
            style += '--' + prefix + 'text-decoration-thickness:' + generatePartStyleReferenceCss(fontStyle.textDecorationThickness) + ';';
        }
    }
    return style;
}
