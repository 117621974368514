import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Method, PartPositioning, selectCurrentPartPositioning, SizeChanged, TemplateEditorFacade } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-size-picker',
    templateUrl: './size-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizePickerComponent implements OnDestroy, OnInit, OnChanges {
    @Input()
    label: string;

    @Input()
    showGridInput = true;

    @Input()
    allowedUnits: string[] = ['pixels', 'percentage', 'viewport', 'grid', 'unset', 'fit-content', 'grid-end', 'grid-start'];

    filteredAllowedUnits: string[] = null;

    @Output()
    changeSize: EventEmitter<SizeChanged> = new EventEmitter();

    @Input()
    positions: PartPositioning[];

    partPositioningData$: Observable<{
        currentPartPositioning: PartPositioning;
        partPositions: PartPositioning[];
    }> = combineLatest([this.templateEditorFacade.currentScreenType, this.templateEditorFacade.selectedPart]).pipe(
        filter(([currentScreenType, selectedPart]) => (!!currentScreenType || currentScreenType === 0) && !!selectedPart),
        map(([currentScreenType, selectedPart]) => {
            return {
                currentPartPositioning: selectCurrentPartPositioning(
                    currentScreenType,
                    this.positions ? this.positions : selectedPart.positions
                ),
                partPositions: this.positions ? this.positions : selectedPart.positions,
            };
        })
    );

    subscriptions: Subscription = new Subscription();

    @Input()
    parentType: string;

    constructor(
        public templateEditorFacade: TemplateEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {}
    ngOnChanges() {
        this.filteredAllowedUnits = null;
        this.changeDetectorRef.detectChanges();
        if (this.parentType === 'vertical-list' || this.parentType === 'horizontal-list') {
            this.filteredAllowedUnits = this.allowedUnits.filter(unit => unit !== 'grid' && unit !== 'grid-end' && unit !== 'grid-start');
        } else {
            this.filteredAllowedUnits = this.allowedUnits;
        }
        this.changeDetectorRef.detectChanges();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onChangeSizeX($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.sizeX = $event.viewPort;
                    partPositioning.sizeXUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMinSizeX($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.minSizeX = $event.viewPort;
                    partPositioning.minSizeXUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMaxSizeX($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.maxSizeX = $event.viewPort;
                    partPositioning.maxSizeXUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeSizeY($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.sizeY = $event.viewPort;
                    partPositioning.sizeYUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMinSizeY($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.minSizeY = $event.viewPort;
                    partPositioning.minSizeYUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMaxSizeY($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.maxSizeY = $event.viewPort;
                    partPositioning.maxSizeYUnit = $event.unit;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeGrow($event, partPositioning: PartPositioning) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioning.grow = $event.viewPort;
                    this.onChangeSize(partPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    responseSettingsForSizeX(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.sizeX || partPositioning.sizeX === 0) && partPositioning.sizeXUnit) {
                if (
                    partPositioning.sizeX !== partPositioningData.currentPartPositioning.sizeX ||
                    partPositioning.sizeXUnit !== partPositioningData.currentPartPositioning.sizeXUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.sizeX || partPositioningData.currentPartPositioning.sizeX === 0) &&
                        !!partPositioningData.currentPartPositioning.sizeXUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForGrow(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if (partPositioning.grow || partPositioning.grow === 0) {
                if (partPositioning.grow !== partPositioningData.currentPartPositioning.grow) {
                    otherValues =
                        !!partPositioningData.currentPartPositioning.grow || partPositioningData.currentPartPositioning.grow === 0;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForSizeY(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.sizeY || partPositioning.sizeY === 0) && partPositioning.sizeYUnit) {
                if (
                    partPositioning.sizeY !== partPositioningData.currentPartPositioning.sizeY ||
                    partPositioning.sizeYUnit !== partPositioningData.currentPartPositioning.sizeYUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.sizeY || partPositioningData.currentPartPositioning.sizeY === 0) &&
                        !!partPositioningData.currentPartPositioning.sizeYUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForMinSizeY(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.minSizeY || partPositioning.minSizeY === 0) && partPositioning.minSizeYUnit) {
                if (
                    partPositioning.minSizeY !== partPositioningData.currentPartPositioning.minSizeY ||
                    partPositioning.minSizeYUnit !== partPositioningData.currentPartPositioning.minSizeYUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.minSizeY ||
                            partPositioningData.currentPartPositioning.minSizeY === 0) &&
                        !!partPositioningData.currentPartPositioning.minSizeYUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForMinSizeX(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.minSizeX || partPositioning.minSizeX === 0) && partPositioning.minSizeXUnit) {
                if (
                    partPositioning.minSizeX !== partPositioningData.currentPartPositioning.minSizeX ||
                    partPositioning.minSizeXUnit !== partPositioningData.currentPartPositioning.minSizeXUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.minSizeX ||
                            partPositioningData.currentPartPositioning.minSizeX === 0) &&
                        !!partPositioningData.currentPartPositioning.minSizeXUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForMaxSizeY(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.maxSizeY || partPositioning.maxSizeY === 0) && partPositioning.maxSizeYUnit) {
                if (
                    partPositioning.maxSizeY !== partPositioningData.currentPartPositioning.maxSizeY ||
                    partPositioning.maxSizeYUnit !== partPositioningData.currentPartPositioning.maxSizeYUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.maxSizeY ||
                            partPositioningData.currentPartPositioning.maxSizeY === 0) &&
                        !!partPositioningData.currentPartPositioning.maxSizeYUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    responseSettingsForMaxSizeX(partPositioningData: { currentPartPositioning: PartPositioning; partPositions: PartPositioning[] }) {
        let otherValues = false;
        for (const partPositioning of partPositioningData.partPositions) {
            if ((partPositioning.maxSizeX || partPositioning.maxSizeX === 0) && partPositioning.maxSizeXUnit) {
                if (
                    partPositioning.maxSizeX !== partPositioningData.currentPartPositioning.maxSizeX ||
                    partPositioning.maxSizeXUnit !== partPositioningData.currentPartPositioning.maxSizeXUnit
                ) {
                    otherValues =
                        (!!partPositioningData.currentPartPositioning.maxSizeX ||
                            partPositioningData.currentPartPositioning.maxSizeX === 0) &&
                        !!partPositioningData.currentPartPositioning.maxSizeXUnit;
                    if (otherValues) {
                        break;
                    }
                }
            }
        }
        return otherValues;
    }

    onChangeSize(partPositioning: PartPositioning, existingPartPositionings: PartPositioning[]) {
        const foundPartPositioning = existingPartPositionings.find(
            existingPartPositioning => existingPartPositioning.screenType === partPositioning.screenType
        );

        if (!foundPartPositioning) {
            existingPartPositionings.push(partPositioning);
        } else {
            foundPartPositioning.sizeY = partPositioning.sizeY;
            foundPartPositioning.sizeX = partPositioning.sizeX;
            foundPartPositioning.sizeYUnit = partPositioning.sizeYUnit;
            foundPartPositioning.sizeXUnit = partPositioning.sizeXUnit;

            foundPartPositioning.minSizeY = partPositioning.minSizeY;
            foundPartPositioning.minSizeX = partPositioning.minSizeX;
            foundPartPositioning.minSizeYUnit = partPositioning.minSizeYUnit;
            foundPartPositioning.minSizeXUnit = partPositioning.minSizeXUnit;

            foundPartPositioning.maxSizeY = partPositioning.maxSizeY;
            foundPartPositioning.maxSizeX = partPositioning.maxSizeX;
            foundPartPositioning.maxSizeYUnit = partPositioning.maxSizeYUnit;
            foundPartPositioning.maxSizeXUnit = partPositioning.maxSizeXUnit;

            foundPartPositioning.inheritContentSize = partPositioning.inheritContentSize;
        }
        this.changeSize.emit({
            partPositionings: existingPartPositionings,
        });
    }

    identifyPartPositioning(index, item: PartPositioning) {
        return item.id;
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly filter = filter;
}
