import { createAction, props } from '@ngrx/store';
import { Part } from '../../models/template/part.model';
import { Template } from '../../models/template/template.model';
import { OverviewTemplateDto } from '../../dto/overview/overview-template.dto';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';

export const createTemplateSuccess = createAction('[EFFECT] create new template success', props<{ id: string }>());

export const findTemplate = createAction(
    '[ACTION] fetch template',
    props<{ id: string; templateApplicationId?: string | undefined; languageCode?: string }>()
);
export const refreshTemplate = createAction('[ACTION] refresh template', props<{ id: string }>());

export const refreshTemplateSuccess = createAction('[EFFECT] refresh template success', props<{ template: Template }>());

export const fetchTemplateSuccess = createAction('[EFFECT] fetch template success', props<{ template: Template }>());
export const fetchTemplateError = createAction('[ACTION] fetch template error', props<{ template: Template }>());

export const deleteTemplate = createAction('[ACTION] delete template', props<{ id: string }>());
export const deleteTemplateSuccess = createAction('[EFFECT] delete template success', props<{ id: string }>());

export const updateTemplate = createAction('[ACTION] update template', props<{ template: Template }>());
export const updateTemplateSuccess = createAction('[EFFECT] update template success', props<{ template: Template }>());

// TEMPLATE Editor actions
export const startDrag = createAction('[ACTION] start part drag', props<{ templateId: string; part: Part }>());
export const endDrag = createAction('[EFFECT] end part drag', props<{ templateId: string }>());

export const selectPart = createAction('[ACTION] select part', props<{ templateId: string; part: Part }>());
export const deselectPart = createAction('[EFFECT] deselect part', props<{ templateId: string; deselectedPartId: string }>());

export const openToolpane = createAction('[ACTION] toolpane opened', props<{ templateId: string }>());
export const closeToolpane = createAction('[ACTION] toolpane closed', props<{ templateId: string }>());

export const changeCurrentViewScreenType = createAction(
    '[ACTION] view screentype changed',
    props<{ templateId: string; currentViewScreenType: number }>()
);
export const changeCurrentScreenType = createAction(
    '[ACTION] screentype changed',
    props<{ templateId: string; currentScreenType: number }>()
);

// Editor Dialog Actions
export const clearTemplateDialogData = createAction('[ACTION] template editor dialog data cleared');
export const clearTemplateDialogFilter = createAction('[ACTION] template editor dialog filter cleared');

export const deleteTemplates = createAction('[ACTION] delete templates', props<{ ids: string[] }>());
export const deleteTemplatesSuccess = createAction('[EFFECT] deletes templates success', props<{ ids: string[] }>());

export const loadTemplateDialogData = createAction('[ACTION] load template editor dialog data');
export const loadTemplateDialogSuccess = createAction(
    '[EFFECT] load template editor dialog data success',
    props<{ data: Page<OverviewTemplateDto> }>()
);

export const templateDialogPaginationChanged = createAction(
    '[ACTION] template editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const templateDialogSearchTermChanged = createAction(
    '[ACTION] template editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const templateDialogFacetsChanged = createAction(
    '[ACTION] template editor dialog facets changed',
    props<{ facets: SelectedFacets }>()
);
export const templateDialogFilterPluginsChanged = createAction(
    '[ACTION] template editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
