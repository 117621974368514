export interface PaddingStyleDto {
    padding: string;
}

export function generatePaddingStyle(
    paddingStyle: PaddingStyleDto | null | undefined,
    paddingLeftStyle: PaddingStyleDto | null | undefined,
    paddingRightStyle: PaddingStyleDto | null | undefined,
    paddingTopStyle: PaddingStyleDto | null | undefined,
    paddingBottomStyle: PaddingStyleDto | null | undefined
) {
    let style = '';
    if (paddingStyle) {
        style += generateTypePaddingStyle('padding', paddingStyle);
    }
    if (paddingLeftStyle) {
        style += generateTypePaddingStyle('padding-left', paddingLeftStyle);
    }
    if (paddingTopStyle) {
        style += generateTypePaddingStyle('padding-top', paddingTopStyle);
    }
    if (paddingRightStyle) {
        style += generateTypePaddingStyle('padding-right', paddingRightStyle);
    }
    if (paddingBottomStyle) {
        style += generateTypePaddingStyle('padding-bottom', paddingBottomStyle);
    }
    return style;
}

export function generatePaddingStyleWithVars(
    prefix: string,
    paddingStyle: PaddingStyleDto,
    paddingTopStyle: PaddingStyleDto,
    paddingRightStyle: PaddingStyleDto,
    paddingBottomStyle: PaddingStyleDto,
    paddingLeftStyle: PaddingStyleDto
) {
    const paddingStyleCss = generateTypePaddingStyleWithVars(paddingStyle, '--' + prefix + '-padding');
    const paddingTopStyleCss = generateTypePaddingStyleWithVars(paddingTopStyle, '--' + prefix + '-padding-top');
    const paddingRightStyleCss = generateTypePaddingStyleWithVars(paddingRightStyle, '--' + prefix + '-padding-right');
    const paddingBottomStyleCss = generateTypePaddingStyleWithVars(paddingBottomStyle, '--' + prefix + '-padding-bottom');
    const paddingLeftStyleCss = generateTypePaddingStyleWithVars(paddingLeftStyle, '--' + prefix + '-padding-left');

    return {
        paddingStyleCss,
        paddingTopStyleCss,
        paddingRightStyleCss,
        paddingBottomStyleCss,
        paddingLeftStyleCss,
    };
}

export function generateTypePaddingStyle(type: string, paddingStyleDto: PaddingStyleDto) {
    let style = '';
    if (paddingStyleDto.padding) {
        style += type + ':' + paddingStyleDto.padding + ';';
    }
    return style;
}

export function generateTypePaddingStyleWithVars(paddingStyleDto: PaddingStyleDto, varName: string) {
    let style = '';
    if (paddingStyleDto?.padding) {
        style += varName + ':' + paddingStyleDto.padding + ';';
    }
    return style;
}
