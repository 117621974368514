export interface MarginStyleDto {
    margin?: string;
}

export function generateMarginStyle(
    marginStyle: MarginStyleDto,
    marginLeftStyle: MarginStyleDto,
    marginRightStyle: MarginStyleDto,
    marginTopStyle: MarginStyleDto,
    marginBottomStyle: MarginStyleDto
) {
    let style = '';
    if (marginStyle) {
        style += generateTypeMarginStyle('margin', marginStyle);
    }
    if (marginLeftStyle) {
        style += generateTypeMarginStyle('margin-left', marginLeftStyle);
    }
    if (marginTopStyle) {
        style += generateTypeMarginStyle('margin-top', marginTopStyle);
    }
    if (marginRightStyle) {
        style += generateTypeMarginStyle('margin-right', marginRightStyle);
    }
    if (marginBottomStyle) {
        style += generateTypeMarginStyle('margin-bottom', marginBottomStyle);
    }
    return style;
}

export function generateTypeMarginStyle(type: string, marginStyleDto: MarginStyleDto) {
    let style = '';
    if (marginStyleDto.margin) {
        style += type + ':' + marginStyleDto.margin + ';';
    }
    return style;
}

export function generateTypeMarginStyleWithVars(marginStyleDto: MarginStyleDto, marginVar: string) {
    let style = '';
    if (marginStyleDto?.margin) {
        style += marginVar + ':' + marginStyleDto.margin + ';';
    }
    return style;
}

export function generateMarginStyleWithVars(
    prefix: string | undefined,
    marginStyle: MarginStyleDto,
    marginTopStyle: MarginStyleDto,
    marginRightStyle: MarginStyleDto,
    marginBottomStyle: MarginStyleDto,
    marginLeftStyle: MarginStyleDto
) {
    const marginStyleCss = generateTypeMarginStyleWithVars(marginStyle, '--' + prefix + '-margin');
    const marginTopStyleCss = generateTypeMarginStyleWithVars(marginTopStyle, '--' + prefix + '-margin-top');
    const marginRightStyleCss = generateTypeMarginStyleWithVars(marginRightStyle, '--' + prefix + '-margin-right');
    const marginBottomStyleCss = generateTypeMarginStyleWithVars(marginBottomStyle, '--' + prefix + '-margin-bottom');
    const marginLeftStyleCss = generateTypeMarginStyleWithVars(marginLeftStyle, '--' + prefix + '-margin-left');

    return {
        marginStyleCss,
        marginTopStyleCss,
        marginRightStyleCss,
        marginBottomStyleCss,
        marginLeftStyleCss,
    };
}
