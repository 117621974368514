import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setSeenBillingWarning } from '../statemanagement/actioncreator/company.actioncreator';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { selectSelectedCompanyBilling } from '../statemanagement/selectors/company.selector';
import { CompanyUserRightDto } from '../dto/deprecated/company.user.right.dto';
import { ApplicationDto } from '../../../../../../data-access/editor/src/lib/interfaces/application.dto';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { CompanyDto } from '../../../../../../../../apps/no-code-x-backoffice/src/modules/shared/interfaces/company.dto';
import { CompanyService } from '../../../../../../../../apps/no-code-x-backoffice/src/features/add-company/service/company.service';
import { CompanyIdentityproviderService } from '../../../../../../../../apps/no-code-x-backoffice/src/features/add-company/service/companyidentityprovider.service';
import { CompanyIdentityProvider } from '../../../../../../../../apps/no-code-x-backoffice/src/modules/shared/interfaces/company-identity-provider.dto';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';

@Injectable()
export class CompanySandbox {
    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    constructor(
        private store: Store<ApplicationState>,
        private companyService: CompanyService,
        private companyIdentityproviderService: CompanyIdentityproviderService
    ) {}

    setSeenBillingWarning() {
        this.store.dispatch(setSeenBillingWarning());
    }

    checkBilling(): Observable<{ showWarning: boolean }> {
        return this.store.select(selectSelectedCompanyBilling).pipe(
            map(result => {
                if (result) {
                    const freeAmount = +result.userFreeAmount;
                    const usedAmount = +result.userAmount;
                    return { showWarning: !result.seenWarning && result.totalPrice === 0 && freeAmount <= usedAmount };
                } else {
                    return { showWarning: false };
                }
            })
        );
    }

    getCompany(): Observable<CompanyDto> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                this.companyService.getCompany(currentContext.selectedCompany.id).subscribe(test => console.log(test));
                return this.companyService.getCompany(currentContext.selectedCompany.id);
            })
        );
    }

    getIdentityProviders(): Observable<CompanyIdentityProvider[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.companyIdentityproviderService.getCompanyIdentityProviders(currentContext.selectedCompany.id);
            })
        );
    }

    getIdentityProvider(companyIdentityProviderId: string): Observable<CompanyIdentityProvider> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.companyIdentityproviderService.getCompanyIdentityProvider(
                    currentContext.selectedCompany.id,
                    companyIdentityProviderId
                );
            })
        );
    }

    createIdentityProvider(companyIdentityProvider: CompanyIdentityProvider): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.companyIdentityproviderService.createCompanyIdentityProvider(
                    currentContext.selectedCompany.id,
                    companyIdentityProvider
                );
            })
        );
    }

    updateIdentityProvider(companyIdentityProvider: CompanyIdentityProvider): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.companyIdentityproviderService.updateCompanyIdentityProvider(
                    currentContext.selectedCompany.id,
                    companyIdentityProvider.id,
                    companyIdentityProvider
                );
            })
        );
    }

    deleteIdentityProvider(companyIdentityProvider: CompanyIdentityProvider): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.companyIdentityproviderService.deleteCompanyIdentityProvider(
                    currentContext.selectedCompany.id,
                    companyIdentityProvider.id
                );
            })
        );
    }
}
